import React from 'react'
import { Box, Button, FormControl, IconButton, Input, InputAdornment, InputLabel, LinearProgress, Link, makeStyles, Paper, styled, Typography } from '@material-ui/core'
import i18n from '../../i18n';
import teladocIcon from '../../assets/images/teladocicon.png'
import { Visibility, VisibilityOff } from '@material-ui/icons';
import clsx from 'clsx';
import { generateP, gotSpecialCharacterMin1, isNumber } from './functionPass';
import { changePass, validateTokenChangePass } from '../../services/changePassFunctions';
import { toast } from 'react-hot-toast';
import { MyContext } from '../../context/contextChangePass/contextChangePass';

const useStyles = makeStyles(() => ({
    margin: {
        marginBottom: -27
    },
    textField: {
      width: '35ch',
    },
}));

const BorderLinearProgress = styled(LinearProgress)(({ theme, color }) => ({
    height: 7,
    borderRadius: 4,
    background: color
}));

const ChangePasswordForm = ({tokenUUID}) => {

  const classes = useStyles();
  const [passwordNew, setPasswordNew] = React.useState('');
  const [showPasswordNew, setShowPasswordNew] = React.useState(false);
  
  const [passwordRepeat, setPasswordRepeat] = React.useState('');
  const [showPasswordRepeat, setShowPasswordRepeat] = React.useState(false);
  
  const [errorEqualsPasswords, setErrorEqualsPasswords] = React.useState(false);

  const [progressCaracteres, setProgressCaracteres] = React.useState(0);
  const [progressNumber, setProgressNumber] = React.useState(0);
  const [progressCapitalLetter, setProgressCapitalLetter] = React.useState(0);
  const [progressLowerCaseLetter, setProgressLowerCaseLetter] = React.useState(0);
  const [progressSpecialCharacter, setProgressSpecialCharacter] = React.useState(0);
  const [backLoginWithNewPassword, setBackLoginWithNewPassword] = React.useState(false);
  const [loadSendEmailChangePass, setLoadSendEmailChangePass] = React.useState(false)

  const handleChangePasswordNew = (e) => {
    e.preventDefault();
    if(passwordNew !== passwordRepeat || progressCaracteres === 0 || progressNumber === 0 || progressCapitalLetter === 0 || progressSpecialCharacter === 0 || progressLowerCaseLetter === 0) {
        setErrorEqualsPasswords(true)
    } else {
        setErrorEqualsPasswords(false)
        // Call backend action and notifications
        validateTokenChangePass(tokenUUID).then(res => {
            //  Change password
            changePass(tokenUUID, passwordNew, setLoadSendEmailChangePass).then(res => {
                if(res?.status === "ERROR") {
                    // let messageSimple = res?.message.replace(/['"]+/g, '').replace(/\\/g, "/").toString();
                    toast.error(`ERROR: ${res?.message}`, {
                      duration: 10000,
                      style: {
                        maxWidth: "70%",
                        backgroundColor: '#EFEFEF',
                        fontSize: '20px',
                        fontWeight: 'bold',
                      },
                    });
                } else {
                    setBackLoginWithNewPassword(true)
                    toast.success(i18n.t("notificationSuccessChangePass"), {
                      duration: 20000,
                      style: {
                          backgroundColor: '#EFEFEF',
                          fontSize: '20px',
                          fontWeight: 'bold',
                      },
                    });
                }  
            }).catch (error => {
                toast.error(`ERROR: ${error}`, {
                    duration: 20000,
                    style: {
                        backgroundColor: '#EFEFEF',
                        fontSize: '20px',
                        fontWeight: 'bold',
                    },
                });
            })
        }).catch (error => {
            toast.error(`ERROR: ${error}`, {
                duration: 20000,
                style: {
                    backgroundColor: '#EFEFEF',
                    fontSize: '20px',
                    fontWeight: 'bold',
                },
            });
        })
    }
  }

  const setPasswordGenerated = () => {
    let newPassGenerated = generateP();
    setPasswordNew(newPassGenerated)
    setPasswordRepeat(newPassGenerated)
   }

  const handleClickShowPassword = () => {
    setShowPasswordNew(!showPasswordNew)
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleClickShowPasswordRepeat = () => {
    setShowPasswordRepeat(!showPasswordRepeat)
  };
  const handleMouseDownPasswordRepeat = (event) => {
    event.preventDefault();
  };

  const myContext = React.useContext(MyContext);
  React.useEffect(() => {
    // Verify token is valid
    validateTokenChangePass(tokenUUID).then(res => {
        if(res?.status === "ERROR") {
             toast.error(i18n.t("errorInvalidToken"), {
            duration: 5000,
            style: {
                backgroundColor: '#EFEFEF',
                fontSize: '20px',
                fontWeight: 'bold',
            },
            });
            myContext.setChangePass(true)
        }
    }).catch (error => {
        toast.error(i18n.t("errorInvalidToken"), {
            duration: 5000,
            style: {
                backgroundColor: '#EFEFEF',
                fontSize: '20px',
                fontWeight: 'bold',
            },
        });
        myContext.setChangePass(true)
    });
  },[])

  React.useEffect(() => {
    setErrorEqualsPasswords(false)
    // Caracteres
    if(passwordNew.length >= 8) setProgressCaracteres(100)
    else setProgressCaracteres(0)

    // Special character
    if(gotSpecialCharacterMin1(passwordNew)) setProgressSpecialCharacter(100)
    else setProgressSpecialCharacter(0)
    // Uppercase letter
    // to check if there are uppercase letters convert what we do is the string 
    // to lowercase and compare with a strict string if it gives us true it is 
    // that there are no uppercase letters and false otherwise
    let passwordNewLower = passwordNew.toLowerCase()
    if(passwordNewLower == passwordNew) setProgressCapitalLetter(0)
    else setProgressCapitalLetter(100)
    // Lower case letter
    let passwordNewUpperCase = passwordNew.toUpperCase()
    if(passwordNewUpperCase == passwordNew) setProgressLowerCaseLetter(0)
    else setProgressLowerCaseLetter(100)

    passwordNew.split('').some(letter => {
        if(isNumber(letter)) {
            setProgressNumber(100)
            return true;
        } else {
            setProgressNumber(0)
            return false
        }
        });
    if(passwordNew.length === 0) setProgressNumber(0)
  }, [passwordNew]);

  return (
   
    <>
      <Box sx={{ width: "100%", height: 40, backgroundColor: "#331f65", flex: 1, position: "absolute", top: 0 }} />
      <Box sx={{ height: "calc(100vh - 100px)", display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: "column"}}>
        <Link 
            href="/"
        >
            <img src={teladocIcon} alt="logo porciplanet" width={190} style={{marginTop: 40, marginBottom: -40}}/>
        </Link>
        
        {!backLoginWithNewPassword ? 
            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    '& > :not(style)': {
                    margin: '35.5px auto',
                    pt: 2,
                    width: 450,
                    height: 505,
                    },
                }}
            >
                <Paper elevation={3} style={{border: "1px solid #5b2f9175"}}>
                <form onSubmit={handleChangePasswordNew}>
                    {/* <div>tokenUUID: {tokenUUID}</div> */}
                    <Typography variant="h1" style={{ fontSize: "30px", 
                        color: "#331f65", textAlign: 'center',
                        backgroundColor: "#fff",
                        borderRadius: '15px 15px 0px 0px',
                        margin: '20px 0 20px 0',
                        borderColor: "#331f65",
                        letterSpacing: 0.5
                    }}>
                        {i18n.t('changePassword')}
                    </Typography>
                    
                    <Typography variant="body2" style={{textAlign: 'center', fontSize: "14px"}}>
                        {i18n.t('changePasswordViewMessage')}
                    </Typography>

                    <Box style={{margin: '10px 55px 30px 55px', display: "flex", alignItems: "center", flexDirection: "column"}}>
                        <FormControl className={clsx(classes.margin, classes.textField)}>
                            <InputLabel htmlFor="new-password">{i18n.t('newPassword')}</InputLabel>
                            <Input
                                id="new-password"
                                type={showPasswordNew ? 'text' : 'password'}
                                name="password"
                                onChange={e => setPasswordNew(e.target.value)}
                                value={passwordNew}
                                style={{marginBottom: "40px"}}
                                endAdornment={
                                    <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                    >
                                        {showPasswordNew ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                        <Typography variant="caption" gutterBottom style={{fontSize: '13px', width: "85%", textAlign: "justify"}}>
                            {i18n.t('passwordSecure')}
                        </Typography>  

                        <Box sx={{ width: '90%', display: 'flex', flexDirection: 'row', gap: 5, mt: 2, mb: 2}}>
                            <Box sx={{ width: '24%' }}>
                                <BorderLinearProgress variant="determinate" value={progressCaracteres} color="primary"/>
                            </Box>
                            <Box sx={{ width: '24%' }}>
                                <BorderLinearProgress variant="determinate" value={progressNumber} color="secondary"/>
                            </Box>
                            <Box sx={{ width: '24%' }}>
                                <BorderLinearProgress variant="determinate" value={progressCapitalLetter} color="primary"/> 
                            </Box>
                            <Box sx={{ width: '24%' }}>
                                <BorderLinearProgress variant="determinate" value={progressSpecialCharacter} color="secondary"/>
                            </Box>
                        </Box>
                        
                        <FormControl className={clsx(classes.margin, classes.textField)}>
                            <InputLabel htmlFor="new-password-repeat">{i18n.t('repeatPassword')}</InputLabel>
                            <Input
                                id="new-password-repeat"
                                type={showPasswordRepeat ? 'text' : 'password'}
                                name="passwordRepeat"
                                onChange={e => setPasswordRepeat(e.target.value)}
                                value={passwordRepeat}
                                style={{marginBottom: "40px"}}
                                endAdornment={
                                    <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPasswordRepeat}
                                        onMouseDown={handleMouseDownPasswordRepeat}
                                    >
                                        {showPasswordRepeat ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                        {
                            errorEqualsPasswords ? <Typography variant="caption" color='error' gutterBottom style={{fontSize: '13px', width:'89%', marginBottom: -15}}>
                            {i18n.t('errorChangePassword')}
                            </Typography>: null
                        }
                    </Box>
                    <Box sx={{display:'flex', flexDirection: 'column', width:"100%"}}>
                        <Button
                            type="submit"
                            autoFocus={true}
                            color="primary"
                            variant="contained"
                            style={{width:"70%", margin: '-7px auto 20px auto'}}
                            disabled={passwordNew ==='' || passwordRepeat ===''}
                            disableElevation={false}
                            disableFocusRipple={false}
                            disableRipple={false}
                        >
                            { loadSendEmailChangePass ? <>{i18n.t('confirming')}</>
                                : <>{i18n.t('confirm')}</>
                            }
                        </Button>
                        
                        <Button style={{textTransform: "lowercase", width: "90%", margin: 'auto', background: "#e8fcff"}} onClick={setPasswordGenerated}>
                            <span style={{fontWeight: 450}}><span style={{textTransform: "uppercase"}}>G</span>{i18n.t('generatePassword')}</span>
                        </Button>
                        <Link 
                            style={{
                                textAlign: "center",
                                fontSize: 13,
                                textTransform: 'none',
                                cursor: 'pointer',
                                padding: 20,
                                color: "#331f65",
                                fontWeight: "500",
                                letterSpacing: 0.5,
                            }}
                            underline="none"
                            href="/"
                        >
                            {i18n.t('backToLogin')}
                        </Link>
                    </Box> 
                </form> 
                </Paper>
            </Box>
        : <>
            <Link 
                style={{
                    fontSize: 13,
                    textTransform: 'none',
                    cursor: 'pointer',
                    marginTop: 50,
                    marginBottom: 50,
                    background: "#003468",
                    padding: 12,
                    paddingLeft: 20,
                    paddingRight: 20,
                    borderRadius: 5,
                    color: "#fff",
                    fontWeight: "500",
                    letterSpacing: 0.5,
                }}
                underline="none"
                href="/"
            >
                {i18n.t('backToLogin')}
            </Link>
          </>
        }
      </Box>
    </>
  )
}

export default ChangePasswordForm


