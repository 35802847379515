import { useContext, useState } from 'react';
import { GlobalContext } from '../../context/Provider';
import { convertDate } from '../Filters';
import Cookies from 'js-cookie';

const useForm = () => {
    const {
        casosState: { selected },
        authState: { user },
    } = useContext(GlobalContext);
    const [form, setForm] = useState(selected);
    // console.log(selected);
    const onChange = (e) => {
        const target = e.target;
        const value =
            target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        setForm({ ...form, [name]: value });
    };

    const initForm = (userInit) => {
        setForm({ ...userInit });
    };
    let superUser = false;
    // if (user && user.role === 'AdminCA') {
    if (Cookies.get('role') === 'AdminCA') {
        superUser = true;
    // } else if (user && user.role === 'AgenteCA') {
    } else if (Cookies.get('role') === 'AgenteCA') {
        superUser = true;
    // } else if (user && user.role === 'AgenteSinSensiblesCA') {
    } else if (Cookies.get('role') === 'AgenteSinSensiblesCA') {
        superUser = true;
    }
    const monthDiff = (dateFrom) => {
        var date1 = convertDate(dateFrom.split(' ')[0]);
        var date2 = new Date();
        var diff = Math.abs(date1.getTime() - date2.getTime());

        return diff / (24 * 60 * 60 * 1000 * 30);
    };
    const changeTelefon =
        (selected.statusId === '1' ||
            selected.statusId === '5' ||
            selected.statusId === '4' ||
            selected.statusId === '19') &&
        superUser;
    const cancelCaso =
        selected.statusId !== '2' &&
        selected.statusId !== '3' &&
        selected.statusId !== '5' &&
        superUser;
    const activateCaso =
        (selected.statusId === '2' ||
            selected.statusId === '3' ||
            selected.statusId === '5') &&
        superUser &&
        monthDiff(selected.startStatusDate) <= 6;
    const uploadActive = superUser && monthDiff(selected.startStatusDate) >= 6;

    return {
        form,
        onChange,
        initForm,
        changeTelefon,
        cancelCaso,
        activateCaso,
        uploadActive,
        superUser,
    };
};

export default useForm;
