import {
  Dialog,
  Button,
  DialogTitle,
  DialogContent,
  Grid,
  Typography,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";

import FileCopyIcon from "@material-ui/icons/FileCopy";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import "./cargamasiva.scss";
import { useContext, useRef, useState } from "react";
import axiosInstance from "../../helpers/axiosInstance";
import toast from "react-hot-toast";
import { GlobalContext } from "../../context/Provider";
import { CASOS_ERROR } from "../../constants/actionTypes";
import i18n from '../../i18n';
import { handleUnauthorizedError } from "../../services/Error401Service";
const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
}));

const CargaMasiva = ({ openCarga, handleCloseCarga }) => {
  const {
    casosDispatch,
    casosState: { newForm },
  } = useContext(GlobalContext);

  const fileRef = useRef(null);
  const classes = useStyles();
  const [fileName, setFileName] = useState();
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState();
  const handleFileInput = (e) => {
    const file = e.target.files[0];
    setFileName(file.name);
    setFile(file);
  };
  const demoFile = () => {
    axiosInstance()
      .post("/v1/load/excel/template", {})
      .then((res) => {
        if (res.data?.status === "ok") {
          const linkSource =
            "data:application/vnd.ms-excel;base64," + res.data.template;
          const downloadLink = document.createElement("a");
          downloadLink.href = linkSource;
          downloadLink.download = "template.xlsx";
          downloadLink.click();
        }
      })
      .catch((err) => {
        console.log(err);
        handleUnauthorizedError(err);
      });
  };

  const handleClick = () => {
    setLoading(true);
    var extension = fileName.match(/\.[0-9a-z]+$/i);
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      const data = {
        fileName: fileName,
        fileType: extension[0],
        base64File: reader.result,
      };
      axiosInstance()
        .post("/v1/load/file/base64", data)
        .then((res) => {
          if (res.data?.status === "ok") {
            toast.success(i18n.t('cargamasiva.index.toast.cargamasivaok'), {
              duration: 20000,
              style: {
                backgroundColor: "#EFEFEF",
                fontSize: "20px",
                fontWeight: "bold",
              },
            });
            setFileName("");
            setLoading(false);
            fileRef.current.value = "";
          } else {
            casosDispatch({
              type: CASOS_ERROR,
              payload: i18n.t('cargamasiva.index.toast.cargamasivako') + res.data.message,
            });
            setFileName("");
            setLoading(false);
            fileRef.current.value = "";
          }
        })
        .catch((err) => {
          console.log(err);
          handleUnauthorizedError(err);
          casosDispatch({
            type: CASOS_ERROR,
            payload: i18n.t('cargamasiva.index.toast.cargamasivacatch') + err,
          });
          setFileName("");
          setLoading(false);
          fileRef.current.value = "";
        });
    };
    reader.onerror = function (error) {
      console.log(i18n.t('error'), error);
    };
  };
  return (
    <Dialog
      fullScreen
      open={openCarga}
      onClose={handleCloseCarga}
      scroll={"paper"}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle
        id="scroll-dialog-title"
        style={{ paddingTop: 5, paddingBottom: 5 }}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={6}>
            <Typography variant="h6" color="primary">
            {i18n.t('cargamasiva.index.label.cargamasiva')}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              <IconButton
                size="small"
                edge="start"
                color="inherit"
                onClick={handleCloseCarga}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers={true}>
        <div className="carga-masiva">
          <Typography
            variant="h6"
            component="span"
            color="primary"
            style={{ marginBottom: 15 }}
          >
            {fileName}
          </Typography>
          <input
            ref={fileRef}
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            className={classes.input}
            id="contained-button-file"
            type="file"
            onChange={handleFileInput}
          />
          <label htmlFor="contained-button-file">
            <Button variant="contained" color="secondary" component="span">
              <FileCopyIcon style={{ marginRight: 15 }} />{" "}
              {fileName ? i18n.t('cargamasiva.index.label.cambiar') : i18n.t('cargamasiva.index.label.seleccionar')}
            </Button>
          </label>
          {!newForm?.company?.fileCustom && (
            <Typography
              variant="body1"
              component="span"
              color="primary"
              style={{ marginTop: 15, cursor: "pointer" }}
              onClick={demoFile}
            >
              {i18n.t('cargamasiva.index.label.archivodeejemplo')}
              
            </Typography>
          )}
          {fileName && (
            <Button
              variant="contained"
              color="primary"
              component="span"
              style={{ marginTop: 15 }}
              onClick={handleClick}
              disabled={loading}
            >
              <CloudUploadIcon style={{ marginRight: 15 }} />{" "}
              {loading ? i18n.t('cargamasiva.index.label.cargando') : i18n.t('cargamasiva.index.label.subircargamasiva')}
            </Button>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default CargaMasiva;
