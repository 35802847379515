export const LOGIN_LOADING = "LOGIN_LOADING";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGOUT_USER = "LOGOUT_USER";

export const CASOS_LOADING = "CASOS_LOADING";
export const CASOS_REMOVE_LOADING = "CASOS_REMOVE_LOADING";
export const CASOS_SUCCESS = "CASOS_SUCCESS";
export const CASOS_ERROR = "CASOS_ERROR";
export const CASO_SELECTED = "CASOS_SELECTED";
export const CASO_DESELECTED = "CASO_DESELECTED";
export const CASO_NEW = "CASO_NEW";
export const CHANGE_SEARCH = "CHANGE_SEARCH";
export const CLEAR_SEARCH = "CLEAR_SEARCH";
export const FILTERS_LIST = "FILTERS_LIST";
export const CASO_SELECTED_DOCUMENTS = "CASO_SELECTED_DOCUMENTS";
export const CHANGE_PAGE = "CHANGE_PAGE";
