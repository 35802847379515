import React from "react";

export const MyContext = React.createContext({
    changePass: "",
    setChangePass: () => {}
});
  
export const MyContextProvider = props => {
    const [changePass, setChangePass] = React.useState(false);

    return (
    <MyContext.Provider value={{ changePass, setChangePass }}>
        {props.children}
    </MyContext.Provider>
    );
};