import {
  LOGIN_LOADING,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  LOGOUT_USER,
} from "../../constants/actionTypes";

const auth = (state, { payload, type }) => {
  switch (type) {
    case LOGIN_LOADING:
      return {
        ...state,
        error: false,
        loading: true,
      };

    case LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        user: payload,
      };

    case LOGIN_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case LOGOUT_USER:
      return {
        loading: false,
      };
    default:
      return state;
  }
};

export default auth;
