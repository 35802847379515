import axios from "axios";

const axiosInstance = (login = false) => {
  const baseURL = process.env.REACT_APP_BACKEND_URL;

  const axiosInstance = axios.create({
    withCredentials: true,
    baseURL: baseURL,
  });

  axiosInstance.interceptors.response.use(
    (response) =>
      new Promise((resolve) => {
        resolve(response);
      }),
    (error) => {

        return new Promise((resolve, reject) => {
          reject(error);
        });
      }
  );

  return axiosInstance;
};

export default axiosInstance;
