import {
  CASOS_LOADING,
  CASOS_SUCCESS,
  CASOS_ERROR,
  CASO_SELECTED,
  CASO_NEW,
  CHANGE_SEARCH,
  CLEAR_SEARCH,
  FILTERS_LIST,
  CASO_SELECTED_DOCUMENTS,
  CHANGE_PAGE,
  CASOS_REMOVE_LOADING,
  LOGOUT_USER,
} from "../../constants/actionTypes";

const casos = (state, { payload, type }) => {
  switch (type) {
    case CASOS_LOADING:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case CASOS_REMOVE_LOADING:
      return {
        ...state,
        error: false,
        loading: false,
      };

    case CASOS_SUCCESS:
      return {
        ...state,
        loading: false,
        ...payload,
        search: {
          ...state.search,
          ...payload.search,
        },
      };

    case CASOS_ERROR:
      return {
        ...state,

        loading: false,
        error: payload,
      };
    case CASO_SELECTED:
      return {
        ...state,
        loading: false,
        selected: payload,
      };
    case CASO_NEW:
      return {
        ...state,
        loading: false,
        newForm: payload,
      };
    case CHANGE_SEARCH:
      return {
        ...state,
        search: {
          ...state.search,
          ...payload,
        },
      };
    case CLEAR_SEARCH:
      return {
        ...state,
        search: {
          pageable: 0,
          orderBy: "numRequest",
          orderASCorDESC: "DESC",
        },
      };
    case CHANGE_PAGE:
      return {
        ...state,
        pageable: payload,
        search: {
          ...state.search,
          pageable: payload,
          orderBy: "numRequest",
          orderASCorDESC: "DESC",
        },
      };
    case FILTERS_LIST:
      return {
        ...state,
        filterList: { ...payload },
      };
    case CASO_SELECTED_DOCUMENTS:
      return {
        ...state,
        selected: {
          ...state.selected,
          documents: payload,
        },
      };
    case LOGOUT_USER:
      return {
        loading: false,
        insureds: [],
        orderASCorDESC: "DESC",
        orderBy: "numRequest",
        pageable: 0,
        resultsPerPage: 0,
        search: {
          pageable: 0,
          orderBy: "numRequest",
          orderASCorDESC: "DESC",
        },
        filterList: {},
        newform: null,
        totalResults: 0,
        error: null,
        selected: null,
      };
    default:
      return state;
  }
};

export default casos;
