import React from "react";
import Menu from "../../components/Menu";
import isAuthenticated from "../../utils/isAuthenticated";
import "./layout.scss";
import Inbox from "../Inbox";
import Login from "../Login";

import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import toast, { Toaster } from "react-hot-toast";
import { GlobalContext } from "../../context/Provider";
import { useContext } from "react";
import { useEffect } from "react";
import { loginWithToken } from "../../context/actions/auth/login";
import { CircularProgress } from "@material-ui/core";
import ChangePasswordUsername from "../ChangePassword/changePasswordUsername";
import { MyContext } from "../../context/contextChangePass/contextChangePass";
import ChangePasswordForm from "../ChangePassword/changePasswordForm";
import Cookies from "js-cookie";

const theme = createTheme({
  palette: {
    primary: {
      main: "#003468",
    },
    secondary: {
      main: "#333333",
    },
  },
});

const Layout = () => {
  const { authDispatch, authState, casosState, loginOK } = useContext(GlobalContext);
  const myContext = React.useContext(MyContext);

  let tokenUUID = null;

  if(window?.location?.search) {
    const valores = window.location.search;
    const urlParams = new URLSearchParams(valores);
    tokenUUID = urlParams.get('tokenUUID');
  }

    useEffect(() => {
      if (authState.user === undefined) {
          loginWithToken()(authState,authDispatch);
      }
    }, [authDispatch, authState.user]);

  useEffect(() => {
    if (casosState.error) {
      toast.error(casosState.error, {
        duration: 5000,
        style: {
          backgroundColor: "#EFEFEF",
          fontSize: "20px",
          fontWeight: "bold",
        },
      });
    }
  }, [casosState]);

  return (
    <ThemeProvider theme={theme}>
      <div className="layout">
        {authState.loading || casosState.loading ? (
          <div className="loading">
            <CircularProgress color="primary" />
          </div>
        ) : null}
        <Toaster position="top-center" reverseOrder={false} />
        {((loginOK && loginOK === undefined) || Cookies.get("loginOK")) && <Menu />}
        {myContext.changePass && <ChangePasswordUsername />} 
        {((!loginOK && loginOK === undefined) || !Cookies.get("loginOK")) && !myContext.changePass && tokenUUID === null && <Login />}
        {tokenUUID !== null && !authState.user && <ChangePasswordForm tokenUUID={tokenUUID}/>} 
        {((loginOK && loginOK === undefined) || Cookies.get("loginOK")) && <Inbox />}
      </div>
    </ThemeProvider>
  );
};

export default Layout;
