export const colorAzul1 = "#003468";
export const colorAzul2 = "#6e9cc9";
export const colorAzul3 = "#b5cce4";
export const colorAzul4 = "#c7d8e9";
export const colorAzul5 = "#e7eff8";
export const colorAzul6 = "#f2f5f7";
export const colorGris1 = "#333333";
export const colorGris2 = "#aaaaaa";
export const colorGris3 = "#eaeaea";
export const colorGris4 = "#f0f0f0";
export const colorRojo1 = "#e40e0e";
export const colorRojo2 = "#fce6e6";
export const colorBlanco = "white";
export const colorPurple = "#5b2f91";
export const colorBlueLigth = "#00bce7";
