import {
    Dialog,
    Button,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Grid,
    TextField,
    Typography,
    MenuItem,
    IconButton,
    Box,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useContext, useRef } from 'react';
import axiosInstance from '../../helpers/axiosInstance';
import { genders, horarios } from './constants';
import useForm from './useForm';
import './casoForm.scss';
import toast from 'react-hot-toast';
import DocumentsMenu from './Documents';
import { GlobalContext } from '../../context/Provider';
import { getCasos, selectCaso } from '../../context/actions/casos/casos';
import {
    CASOS_LOADING,
    CASOS_REMOVE_LOADING,
} from '../../constants/actionTypes';
import i18n from '../../i18n';
import { handleUnauthorizedError } from '../../services/Error401Service';

const CasoForm = ({ open }) => {
    const {
        casosState: { selected, search, pageable },
        casosDispatch,
    } = useContext(GlobalContext);

    const descriptionElementRef = useRef(null);
    const {
        form,
        onChange,
        changeTelefon,
        cancelCaso,
        activateCaso,
        uploadActive,
        superUser,
    } = useForm();

    const handleClose = () => {
        selectCaso(null)(casosDispatch);
    };
    const handleSubmit = () => {
        casosDispatch({
            type: CASOS_LOADING,
        });
        const data = {
            insured: form,
        };
        axiosInstance()
            .post('/v1/modify/phone', data)
            .then((res) => {
                if (res.data?.status === 'ok') {
                    toast.success(i18n.t('casoform.index.toast.solicitudguardada'), {
                        duration: 20000,
                        style: {
                            backgroundColor: '#EFEFEF',
                            fontSize: '20px',
                            fontWeight: 'bold',
                        },
                    });
                    getCasos({ search, pageable: pageable })(casosDispatch);
                    handleClose();
                } else {
                    toast.error(res.data?.message, {
                        duration: 10000,
                        style: {
                            backgroundColor: '#EFEFEF',
                            fontSize: '20px',
                            fontWeight: 'bold',
                        },
                    });
                    casosDispatch({
                        type: CASOS_REMOVE_LOADING,
                    });
                }
            })
            .catch((err) => {
                handleUnauthorizedError(err);
                toast.error(err, {
                    duration: 20000,
                    style: {
                        backgroundColor: '#EFEFEF',
                        fontSize: '20px',
                        fontWeight: 'bold',
                    },
                });
            });
    };
    const handleActivate = () => {
        const data = {
            insured: form,
        };
        axiosInstance()
            .post('/v1/modify/reactivate', data)
            .then((res) => {
                if (res.data?.status === 'ok') {
                    toast.success(i18n.t('casoform.index.toast.solicitudreactivada'), {
                        duration: 20000,
                        style: {
                            backgroundColor: '#EFEFEF',
                            fontSize: '20px',
                            fontWeight: 'bold',
                        },
                    });
                    getCasos({ search, pageable: pageable })(casosDispatch);
                    handleClose();
                } else {
                    toast.error(res.data?.message, {
                        duration: 10000,
                        style: {
                            backgroundColor: '#EFEFEF',
                            fontSize: '20px',
                            fontWeight: 'bold',
                        },
                    });
                }
            })
            .catch((err) => {
                handleUnauthorizedError(err);
                toast.error(err, {
                    duration: 20000,
                    style: {
                        backgroundColor: '#EFEFEF',
                        fontSize: '20px',
                        fontWeight: 'bold',
                    },
                });
                casosDispatch({
                    type: CASOS_REMOVE_LOADING,
                });
            });
    };
    const handleCancel = () => {
        if (window.confirm(i18n.t('casoform.index.confirm.anularexpediente'))) {
            const data = {
                insured: form,
            };
            axiosInstance()
                .post('/v1/modify/cancel', data)
                .then((res) => {
                    if (res.data?.status === 'ok') {
                        toast.success(i18n.t('casoform.index.toast.solicitudcancelada'), {
                            duration: 20000,
                            style: {
                                backgroundColor: '#EFEFEF',
                                color: '#e40e0e',
                                fontWeight: 'bold',
                            },
                        });
                        getCasos({ search, pageable: pageable })(casosDispatch);
                        handleClose();
                    } else {
                        toast.error(res.data?.message, {
                            duration: 10000,
                            style: {
                                backgroundColor: '#EFEFEF',
                                fontSize: '20px',
                                fontWeight: 'bold',
                            },
                        });
                        casosDispatch({
                            type: CASOS_REMOVE_LOADING,
                        });
                    }
                })
                .catch((err) => {
                    handleUnauthorizedError(err);
                    toast.error(err, {
                        duration: 20000,
                        style: {
                            backgroundColor: '#EFEFEF',
                            fontSize: '20px',
                            fontWeight: 'bold',
                        },
                    });
                });
        }
    };

    return (
        <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
            scroll={'paper'}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
        >
            <DialogTitle
                id="scroll-dialog-title"
                style={{ paddingTop: 5, paddingBottom: 5 }}
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Grid item xs={6}>
                        <Typography
                            variant="subtitle2"
                            component="span"
                            color="secondary"
                        >
                            {selected?.name} {i18n.t('casoform.index.header.subidopor')} {' '}
                            {selected.userLoadedBy}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center"
                        >
                            <IconButton
                                size="small"
                                edge="start"
                                color="inherit"
                                onClick={handleClose}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent dividers={true}>
                <DialogContentText
                    id="scroll-dialog-description"
                    ref={descriptionElementRef}
                    tabIndex={-1}
                >
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="flex-start"
                        spacing={3}
                    >
                        <Grid item xs={6}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Typography
                                        variant="title1"
                                        component="h3"
                                        color="primary"
                                    >
                                        {i18n.t('casoform.index.label.datoscliente')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        variant="outlined"
                                        label={i18n.t('casoform.index.label.numsolicitud')}
                                        name="numRequest"
                                        fullWidth
                                        value={selected.numRequest || ''}
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        variant="outlined"
                                        label={i18n.t('casoform.index.label.numasegurado')}
                                        name="numInsured"
                                        fullWidth
                                        value={selected.numInsured || ''}
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        variant="outlined"
                                        label={i18n.t('casoform.index.label.fechacita')}
                                        name="appointmentDate"
                                        fullWidth
                                        value={selected.appointmentDate || ''}
                                        disabled
                                    />
                                </Grid>

                                <Grid item xs={4}>
                                    <TextField
                                        variant="outlined"
                                        label={i18n.t('casoform.index.label.centromedico')}
                                        name="medicalCenter"
                                        fullWidth
                                        value={selected.medicalCenter || ''}
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        variant="outlined"
                                        label={i18n.t('casoform.index.label.compania')}
                                        name="company"
                                        fullWidth
                                        value={selected.company || ''}
                                        disabled
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        variant="outlined"
                                        label={i18n.t('casoform.index.label.observacionesalta')}
                                        name="observations"
                                        fullWidth
                                        multiline
                                        value={selected.observations || ''}
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    {superUser && (
                                        <DocumentsMenu
                                            canUpload={uploadActive}
                                        />
                                    )}
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography
                                        variant="title1"
                                        component="h3"
                                        color="primary"
                                    >
                                        {i18n.t('casoform.index.label.datospersonales')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        variant="outlined"
                                        label={i18n.t('casoform.index.label.sexo')}
                                        name="gender"
                                        fullWidth
                                        value={selected.gender || ''}
                                        select
                                        disabled
                                    >
                                        {genders.map((option, index) => (
                                            <MenuItem
                                                key={`gender-${index}`}
                                                value={option.value}
                                            >
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        variant="outlined"
                                        label={i18n.t('casoform.index.label.nombre')}
                                        name="name"
                                        fullWidth
                                        value={selected.name || ''}
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        variant="outlined"
                                        label={i18n.t('casoform.index.label.apellido1')}
                                        name="surname1"
                                        fullWidth
                                        value={selected.surname1 || ''}
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        variant="outlined"
                                        label={i18n.t('casoform.index.label.apellido2')}
                                        name="surname2"
                                        fullWidth
                                        value={selected.surname2 || ''}
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        variant="outlined"
                                        label={i18n.t('casoform.index.label.nif')}
                                        name="nif"
                                        fullWidth
                                        value={selected.nif || ''}
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        variant="outlined"
                                        label={i18n.t('casoform.index.label.fechanacimiento')}
                                        name="birthDate"
                                        fullWidth
                                        value={selected.birthDate || ''}
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        variant="outlined"
                                        label={i18n.t('casoform.index.label.direccion')}
                                        name="address"
                                        fullWidth
                                        value={selected.address || ''}
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        variant="outlined"
                                        label={i18n.t('casoform.index.label.codigopostal')}
                                        name="postalCode"
                                        fullWidth
                                        value={selected.postalCode || ''}
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        variant="outlined"
                                        label={i18n.t('casoform.index.label.ciudad')}
                                        name="city"
                                        fullWidth
                                        value={selected.city || ''}
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        variant="outlined"
                                        label={i18n.t('casoform.index.label.provincia')}
                                        name="province"
                                        fullWidth
                                        value={selected.province || ''}
                                        disabled
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        {/*  RIGHT GRID */}
                        <Grid item xs={6}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Typography
                                        variant="title1"
                                        component="h3"
                                        color="primary"
                                    >
                                        Datos contacto
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        variant="outlined"
                                        label={i18n.t('casoform.index.label.idioma')}
                                        name="language"
                                        fullWidth
                                        value={form.language || ''}
                                        disabled
                                    />
                                </Grid>

                                <Grid item xs={6}>
                                    <TextField
                                        variant="outlined"
                                        label={i18n.t('casoform.index.label.horariocontacto')}
                                        name="contactHours"
                                        fullWidth
                                        value={form.contactHours || ''}
                                        select
                                        disabled
                                    >
                                        {horarios.map((option, index) => (
                                            <MenuItem
                                                value={option.value}
                                                key={`contactHours-${index}`}
                                            >
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField
                                        variant="outlined"
                                        label={i18n.t('casoform.index.label.prefijo')}
                                        name="phone1prefix"
                                        fullWidth
                                        onChange={onChange}
                                        value={form.phone1prefix || ''}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        disabled={
                                            !changeTelefon && !activateCaso
                                        }
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField
                                        variant="outlined"
                                        label={i18n.t('casoform.index.label.telefono1')}
                                        name="phone1"
                                        fullWidth
                                        onChange={onChange}
                                        value={form.phone1 || ''}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        disabled={
                                            !changeTelefon && !activateCaso
                                        }
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField
                                        variant="outlined"
                                        label={i18n.t('casoform.index.label.prefijo')}
                                        name="phone2prefix"
                                        fullWidth
                                        onChange={onChange}
                                        value={form.phone2prefix || ''}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        disabled={
                                            !changeTelefon && !activateCaso
                                        }
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField
                                        variant="outlined"
                                        label={i18n.t('casoform.index.label.telefono2')}
                                        name="phone2"
                                        fullWidth
                                        onChange={onChange}
                                        value={form.phone2 || ''}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        disabled={
                                            !changeTelefon && !activateCaso
                                        }
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField
                                        variant="outlined"
                                        label={i18n.t('casoform.index.label.prefijo')}
                                        name="phone3prefix"
                                        fullWidth
                                        onChange={onChange}
                                        value={form.phone3prefix || ''}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        disabled={
                                            !changeTelefon && !activateCaso
                                        }
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField
                                        variant="outlined"
                                        label={i18n.t('casoform.index.label.telefono3')}
                                        name="phone3"
                                        fullWidth
                                        onChange={onChange}
                                        value={form.phone3 || ''}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        disabled={
                                            !changeTelefon && !activateCaso
                                        }
                                    />
                                </Grid>
                                {activateCaso || changeTelefon ? (
                                    <Grid item xs={12}>
                                        <TextField
                                            variant="outlined"
                                            label={i18n.t('casoform.index.label.observacionesenmodificacion')}
                                            name="observationsComment"
                                            fullWidth
                                            onChange={onChange}
                                            multiline
                                            value={
                                                form.observationsComment || ''
                                            }
                                            disabled={
                                                !changeTelefon && !activateCaso
                                            }
                                        />
                                    </Grid>
                                ) : (
                                    ''
                                )}

                                <Grid item xs={12}>
                                    <Typography
                                        variant="title1"
                                        component="h3"
                                        color="primary"
                                    >
                                        {i18n.t('casoform.index.label.estados')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box className="scroll">
                                        {form.status?.map((status, index) => {
                                            return (
                                                <Grid
                                                    key={`status-${index}`}
                                                    container
                                                    direction="row"
                                                    justifyContent="space-between"
                                                    alignItems="flex-start"
                                                    spacing={3}
                                                >
                                                    <Grid item xs={12}>
                                                        <Typography
                                                            variant="body1"
                                                            color="primary"
                                                            component="span"
                                                        >
                                                            {
                                                                status.startStatusDate
                                                            }
                                                        </Typography>
                                                        <Typography
                                                            variant="body1"
                                                            color="secondary"
                                                            component="span"
                                                            style={{
                                                                marginLeft: 5,
                                                                marginRight: 5,
                                                                fontWeight:
                                                                    'bold',
                                                            }}
                                                        >
                                                            {status.status}
                                                        </Typography>

                                                        <Typography
                                                            variant="body1"
                                                            color="secondary"
                                                            component="span"
                                                        >
                                                            {status.subStatus}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            );
                                        })}
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography
                                        variant="title1"
                                        component="h3"
                                        color="primary"
                                    >
                                        {i18n.t('casoform.index.label.coberturas')}
                                    </Typography>
                                </Grid>
                                {form.coverages?.map((coverage, index) => {
                                    return (
                                        <>
                                            <Grid
                                                item
                                                xs={9}
                                                key={`coverage-${index}`}
                                            >
                                                <Typography
                                                    variant="body2"
                                                    color="primary"
                                                    component="span"
                                                    style={{ marginRight: 10 }}
                                                >
                                                    {coverage.cbCode}
                                                </Typography>
                                                <Typography
                                                    variant="body1"
                                                    color="secondary"
                                                    component="span"
                                                >
                                                    {coverage.name}
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={3}>
                                                <Typography
                                                    variant="body1"
                                                    color="secondary"
                                                    align="right"
                                                >
                                                    {coverage.cbCapital} €
                                                </Typography>
                                            </Grid>
                                        </>
                                    );
                                })}
                                <Grid item xs={6}>
                                    <Grid item xs={12}>
                                        <Typography
                                            variant="title1"
                                            component="h3"
                                            color="primary"
                                        >
                                            {i18n.t('casoform.index.label.pruebasoriginales')}
                                        </Typography>
                                    </Grid>
                                    {form.originalTests?.map((test, index) => {
                                        return (
                                            <>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    key={`testo-${index}`}
                                                >
                                                    <Typography
                                                        variant="body2"
                                                        color="secondary"
                                                        style={{
                                                            fontWeight: 'bold',
                                                        }}
                                                    >
                                                        {test.healthTestName}
                                                    </Typography>
                                                </Grid>
                                            </>
                                        );
                                    })}
                                </Grid>
                                <Grid item xs={6}>
                                    <Grid item xs={12}>
                                        <Typography
                                            variant="title1"
                                            component="h3"
                                            color="primary"
                                        >
                                            {i18n.t('casoform.index.label.pruebasnecesarias')}
                                        </Typography>
                                    </Grid>
                                    {form.neededTests?.map((test, index) => {
                                        return (
                                            <>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    key={`testn-${index}`}
                                                >
                                                    <Typography
                                                        variant="body2"
                                                        color="secondary"
                                                        style={{
                                                            fontWeight: 'bold',
                                                        }}
                                                    >
                                                        {test.healthTestName}
                                                    </Typography>
                                                </Grid>
                                            </>
                                        );
                                    })}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContentText>
            </DialogContent>
            {superUser && (
                <DialogActions className="buttons-caso-form">
                    <div className="right-buttons">
                        {cancelCaso && (
                            <Button
                                onClick={handleCancel}
                                variant="contained"
                                color="secondary"
                                className="delete"
                                size="small"
                            >
                                {i18n.t('casoform.index.label.anularsolicitud')}
                            </Button>
                        )}
                        {activateCaso && (
                            <Button
                                onClick={handleActivate}
                                variant="contained"
                                color="primary"
                                className="greenButton"
                                size="small"
                            >
                                {i18n.t('casoform.index.label.reactivarexpediente')}
                            </Button>
                        )}

                        {superUser && changeTelefon && (
                            <Button
                                onClick={handleSubmit}
                                variant="contained"
                                color="primary"
                                size="small"
                            >
                                {i18n.t('casoform.index.label.guardarexpediente')}
                            </Button>
                        )}
                    </div>
                </DialogActions>
            )}
        </Dialog>
    );
};

export default CasoForm;
