import {
    Dialog,
    Button,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Grid,
    TextField,
    Typography,
    MenuItem,
    IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useContext, useEffect, useRef, useState } from 'react';
import useForm from './useForm';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { es } from 'date-fns/locale';
import { GlobalContext } from '../../context/Provider';
import i18n from '../../i18n';
import Cookies from 'js-cookie';

const NewCasoForm = ({ open, handleCloseForm }) => {
    const {
        casosState: { newForm },
    } = useContext(GlobalContext);
    
    const companyId = Cookies.get('companyId');
    const descriptionElementRef = useRef(null);
    const [covError, setCovError] = useState(false);
    const {
        onChangeCapital,
        setNumRequest,
        setMediadorCodigo,
        superUser,
        formik,
    } = useForm(handleCloseForm);

    const showCoverage =
        companyId === '6' ||
        companyId === '7' ||
        companyId === '8' ||
        companyId === '9';

    const hideNumRequest =
        companyId === '7';

    const handleClose = () => {
        handleCloseForm();
        formik.resetForm();
        formik.setFieldValue('coverages', newForm?.insured?.coverages);
    };

    useEffect(() => {
        if (hideNumRequest) {
            setMediadorCodigo('0');
            formik.setFieldValue('mediadorCodigo', '');

            setNumRequest('0');
            formik.setFieldValue('numRequest', '0');
        } else {
            setMediadorCodigo('');
            formik.setFieldValue('mediadorCodigo', '0');

            setNumRequest('');
            formik.setFieldValue('numRequest', '');
        }
    }, [open]);

    if (!newForm) {
        return null;
    }
    return (
        <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
            scroll={'paper'}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
        >
            <form onSubmit={formik.handleSubmit}>
                <DialogTitle
                    id="scroll-dialog-title"
                    style={{ paddingTop: 5, paddingBottom: 5 }}
                >
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Grid item xs={6}>
                            <Typography
                                variant="subtitle2"
                                component="span"
                                color="secondary"
                            >
                                {i18n.t('newcasoform.index.label.nuevasolicitud')}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-end"
                                alignItems="center"
                            >
                                <IconButton
                                    size="small"
                                    edge="start"
                                    color="inherit"
                                    onClick={handleClose}
                                    aria-label={i18n.t('newcasoform.index.label.cerrar')}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent dividers={true}>
                    <DialogContentText
                        id="scroll-dialog-description"
                        ref={descriptionElementRef}
                        tabIndex={-1}
                    >
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="flex-start"
                            spacing={3}
                        >
                            <Grid item xs={6}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Typography
                                            variant="title1"
                                            component="h3"
                                            color="primary"
                                        >
                                            {i18n.t('newcasoform.index.label.datoscliente')}
                                        </Typography>
                                    </Grid>
                                    {!hideNumRequest && (
                                        <Grid item xs={6}>
                                            <TextField
                                                InputLabelProps={{
                                                    shrink:
                                                        formik.values
                                                            .numRequest ||
                                                        formik.touched
                                                            .numRequest,
                                                }}
                                                error={Boolean(
                                                    formik.touched.numRequest &&
                                                        formik.errors.numRequest
                                                )}
                                                helperText={
                                                    formik.touched.numRequest &&
                                                    formik.errors.numRequest
                                                }
                                                // value={formik.values.numRequest}
                                                onBlur={formik.handleChange}
                                                // // onBlur={formik.handleBlur}
                                                variant="outlined"
                                                label={i18n.t('newcasoform.index.label.numsolicitud')}
                                                name="numRequest"
                                                fullWidth
                                                disabled={hideNumRequest}
                                            />
                                        </Grid>
                                    )}

                                    <Grid item xs={hideNumRequest ? 12 : 6}>
                                        <TextField
                                            InputLabelProps={{
                                                shrink:
                                                    formik.values.numInsured ||
                                                    formik.touched.numInsured,
                                            }}
                                            variant="outlined"
                                            label={i18n.t('newcasoform.index.label.numasegurado')}
                                            name="numInsured"
                                            fullWidth
                                            error={Boolean(
                                                formik.touched.numInsured &&
                                                    formik.errors.numInsured
                                            )}
                                            helperText={
                                                formik.touched.numInsured &&
                                                formik.errors.numInsured
                                            }
                                            // value={formik.values.numInsured}
                                            onBlur={formik.handleChange}
                                            // // onBlur={formik.handleBlur}
                                        />
                                    </Grid>
                                    {showCoverage && (
                                        <Grid item xs={6}>
                                            <TextField
                                                InputLabelProps={{
                                                    shrink:
                                                        formik.values
                                                            .mediadorCodigo ||
                                                        formik.touched
                                                            .mediadorCodigo,
                                                }}
                                                error={Boolean(
                                                    formik.touched
                                                        .mediadorCodigo &&
                                                        formik.errors
                                                            .mediadorCodigo
                                                )}
                                                helperText={
                                                    formik.touched
                                                        .mediadorCodigo &&
                                                    formik.errors.mediadorCodigo
                                                }
                                                // value={formik.values.mediadorCodigo}
                                                onBlur={formik.handleChange}
                                                // // onBlur={formik.handleBlur}
                                                variant="outlined"
                                                label={i18n.t('newcasoform.index.label.mediador')}
                                                name="mediadorCodigo"
                                                fullWidth
                                                disabled={!showCoverage}
                                            />
                                        </Grid>
                                    )}
                                    <Grid item xs={showCoverage ? 6 : 12}>
                                        <TextField
                                            InputLabelProps={{
                                                shrink:
                                                    formik.values
                                                        .observations ||
                                                    formik.touched.observations,
                                            }}
                                            variant="outlined"
                                            label={i18n.t('newcasoform.index.label.observacionesalta')}
                                            name="observations"
                                            fullWidth
                                            multiline
                                            onBlur={formik.handleChange}
                                            // onBlur={formik.handleBlur}
                                            error={Boolean(
                                                formik.touched.observations &&
                                                    formik.errors.observations
                                            )}
                                            helperText={
                                                formik.touched.observations &&
                                                formik.errors.observations
                                            }
                                            // value={formik.values.observations}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography
                                            variant="title1"
                                            component="h3"
                                            color="primary"
                                        >
                                            {i18n.t('newcasoform.index.label.datospersonales')}
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={3}>
                                        <TextField
                                            InputLabelProps={{
                                                shrink:
                                                    formik.values.gender ||
                                                    formik.touched.gender,
                                            }}
                                            variant="outlined"
                                            label={i18n.t('newcasoform.index.label.sexo')}
                                            name="gender"
                                            fullWidth
                                            select
                                            onBlur={formik.handleChange}
                                            // onBlur={formik.handleBlur}
                                            error={Boolean(
                                                formik.touched.gender &&
                                                    formik.errors.gender
                                            )}
                                            helperText={
                                                formik.touched.gender &&
                                                formik.errors.gender
                                            }
                                        >
                                            {Object.entries(
                                                newForm?.gender
                                            ).map((entry) => {
                                                const [key, value] = entry;

                                                return (
                                                    <MenuItem
                                                        key={key}
                                                        value={key}
                                                    >
                                                        {value}
                                                    </MenuItem>
                                                );
                                            })}
                                        </TextField>
                                    </Grid>

                                    <Grid item xs={3}>
                                        <TextField
                                            InputLabelProps={{
                                                shrink:
                                                    formik.values.name ||
                                                    formik.touched.name,
                                            }}
                                            variant="outlined"
                                            label={i18n.t('newcasoform.index.label.nombre')}
                                            name="name"
                                            fullWidth
                                            onBlur={formik.handleChange}
                                            // onBlur={formik.handleBlur}
                                            error={Boolean(
                                                formik.touched.name &&
                                                    formik.errors.name
                                            )}
                                            helperText={
                                                formik.touched.name &&
                                                formik.errors.name
                                            }
                                            // value={formik.values.name}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            InputLabelProps={{
                                                shrink:
                                                    formik.values.surname1 ||
                                                    formik.touched.surname1,
                                            }}
                                            variant="outlined"
                                            label={i18n.t('newcasoform.index.label.apellido1')}
                                            name="surname1"
                                            fullWidth
                                            onBlur={formik.handleChange}
                                            // onBlur={formik.handleBlur}
                                            error={Boolean(
                                                formik.touched.surname1 &&
                                                    formik.errors.surname1
                                            )}
                                            helperText={
                                                formik.touched.surname1 &&
                                                formik.errors.surname1
                                            }
                                            // value={formik.values.surname1}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            InputLabelProps={{
                                                shrink:
                                                    formik.values.surname2 ||
                                                    formik.touched.surname2,
                                            }}
                                            variant="outlined"
                                            label={i18n.t('newcasoform.index.label.apellido2')}
                                            name="surname2"
                                            fullWidth
                                            onBlur={formik.handleChange}
                                            // onBlur={formik.handleBlur}
                                            error={Boolean(
                                                formik.touched.surname2 &&
                                                    formik.errors.surname2
                                            )}
                                            helperText={
                                                formik.touched.surname2 &&
                                                formik.errors.surname2
                                            }
                                            // value={formik.values.surname2}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            InputLabelProps={{
                                                shrink:
                                                    formik.values.nif ||
                                                    formik.touched.nif,
                                            }}
                                            variant="outlined"
                                            label={i18n.t('newcasoform.index.label.nif')}
                                            name="nif"
                                            fullWidth
                                            onBlur={formik.handleChange}
                                            // onBlur={formik.handleBlur}
                                            error={Boolean(
                                                formik.touched.nif &&
                                                    formik.errors.nif
                                            )}
                                            helperText={
                                                formik.touched.nif &&
                                                formik.errors.nif
                                            }
                                            // value={formik.values.nif}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <MuiPickersUtilsProvider
                                            locale={es}
                                            utils={DateFnsUtils}
                                        >
                                            <KeyboardDatePicker
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink:
                                                        formik.values
                                                            .birthDate ||
                                                        formik.touched
                                                            .birthDate,
                                                }}
                                                label={i18n.t('newcasoform.index.label.fechanacimiento')}
                                                onChange={(val) => {
                                                    formik.setFieldValue(
                                                        'birthDate',
                                                        val
                                                    );
                                                }}
                                                variant="inline"
                                                // onBlur={formik.handleBlur}
                                                error={Boolean(
                                                    formik.touched.birthDate &&
                                                        formik.errors.birthDate
                                                )}
                                                helperText={
                                                    formik.touched.birthDate &&
                                                    formik.errors.birthDate
                                                }
                                                value={formik.values.birthDate}
                                                inputVariant="outlined"
                                                format="dd/MM/yyyy"
                                            />
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            InputLabelProps={{
                                                shrink:
                                                    formik.values.address ||
                                                    formik.touched.address,
                                            }}
                                            variant="outlined"
                                            label={i18n.t('newcasoform.index.label.direccion')}
                                            name="address"
                                            fullWidth
                                            onBlur={formik.handleChange}
                                            // onBlur={formik.handleBlur}
                                            // value={formik.values.address}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            InputLabelProps={{
                                                shrink:
                                                    formik.values.postalCode ||
                                                    formik.touched.postalCode,
                                            }}
                                            variant="outlined"
                                            label={
                                                formik.values.city
                                                    ? i18n.t('newcasoform.index.label.codigopostalob')
                                                    : i18n.t('newcasoform.index.label.codigopostal')
                                            }
                                            name="postalCode"
                                            fullWidth
                                            onBlur={formik.handleChange}
                                            // onBlur={formik.handleBlur}
                                            error={Boolean(
                                                formik.touched.postalCode &&
                                                    formik.errors.postalCode
                                            )}
                                            helperText={
                                                formik.touched.postalCode &&
                                                formik.errors.postalCode
                                            }
                                            // value={formik.values.postalCode}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            InputLabelProps={{
                                                shrink:
                                                    formik.values.city ||
                                                    formik.touched.city,
                                            }}
                                            variant="outlined"
                                            label={
                                                formik.values.postalCode
                                                    ? i18n.t('newcasoform.index.label.ciudadob')
                                                    : i18n.t('newcasoform.index.label.ciudad')
                                            }
                                            name="city"
                                            fullWidth
                                            onBlur={formik.handleChange}
                                            // onBlur={formik.handleBlur}
                                            error={Boolean(
                                                formik.touched.city &&
                                                    formik.errors.city
                                            )}
                                            helperText={
                                                formik.touched.city &&
                                                formik.errors.city
                                            }
                                            // value={formik.values.city}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            InputLabelProps={{
                                                shrink:
                                                    formik.values.province ||
                                                    formik.touched.province,
                                            }}
                                            variant="outlined"
                                            label={i18n.t('newcasoform.index.label.provincia')}
                                            name="province"
                                            fullWidth
                                            onBlur={formik.handleChange}
                                            // onBlur={formik.handleBlur}
                                            // value={formik.values.province}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            {/*  RIGHT GRID */}
                            <Grid item xs={6}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Typography
                                            variant="title1"
                                            component="h3"
                                            color="primary"
                                        >
                                        {i18n.t('newcasoform.index.label.datoscontacto')}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            InputLabelProps={{
                                                shrink:
                                                    formik.values.language ||
                                                    formik.touched.language,
                                            }}
                                            variant="outlined"
                                            label={i18n.t('newcasoform.index.label.idioma')}
                                            name="language"
                                            fullWidth
                                            onBlur={formik.handleChange}
                                            // onBlur={formik.handleBlur}
                                            error={Boolean(
                                                formik.touched.language &&
                                                    formik.errors.language
                                            )}
                                            helperText={
                                                formik.touched.language &&
                                                formik.errors.language
                                            }
                                            // value={formik.values.language}
                                            select
                                        >
                                            {Object.entries(
                                                newForm?.languages
                                            ).map((entry) => {
                                                const [key, value] = entry;

                                                return (
                                                    <MenuItem
                                                        key={key}
                                                        value={key}
                                                    >
                                                        {value}
                                                    </MenuItem>
                                                );
                                            })}
                                        </TextField>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <TextField
                                            InputLabelProps={{
                                                shrink:
                                                    formik.values
                                                        .contactHours ||
                                                    formik.touched.contactHours,
                                            }}
                                            variant="outlined"
                                            label={i18n.t('newcasoform.index.label.horariocontacto')}
                                            name="contactHours"
                                            fullWidth
                                            select
                                            onBlur={formik.handleChange}
                                            // onBlur={formik.handleBlur}
                                            error={Boolean(
                                                formik.touched.contactHours &&
                                                    formik.errors.contactHours
                                            )}
                                            helperText={
                                                formik.touched.contactHours &&
                                                formik.errors.contactHours
                                            }
                                            // value={formik.values.contactHours}
                                        >
                                            {Object.entries(
                                                newForm?.contactHours
                                            ).map((entry) => {
                                                const [key, value] = entry;

                                                return (
                                                    <MenuItem
                                                        key={key}
                                                        value={key}
                                                    >
                                                        {value}
                                                    </MenuItem>
                                                );
                                            })}
                                        </TextField>
                                    </Grid>

                                    <Grid item xs={2}>
                                        <TextField
                                            variant="outlined"
                                            label={i18n.t('newcasoform.index.label.prefijoob')}
                                            name="phone1prefix"
                                            fullWidth
                                            onBlur={formik.handleChange}
                                            // onBlur={formik.handleBlur}
                                            error={Boolean(
                                                formik.touched.phone1prefix &&
                                                    formik.errors.phone1prefix
                                            )}
                                            helperText={
                                                formik.touched.phone1prefix &&
                                                formik.errors.phone1prefix
                                            }
                                            // value={formik.values.phone1prefix}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <TextField
                                            variant="outlined"
                                            label={i18n.t('newcasoform.index.label.telefono1')}
                                            name="phone1"
                                            fullWidth
                                            onBlur={formik.handleChange}
                                            // onBlur={formik.handleBlur}
                                            error={Boolean(
                                                formik.touched.phone1 &&
                                                    formik.errors.phone1
                                            )}
                                            helperText={
                                                formik.touched.phone1 &&
                                                formik.errors.phone1
                                            }
                                            // value={formik.values.phone1}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <TextField
                                            variant="outlined"
                                            label={i18n.t('newcasoform.index.label.prefijo')}
                                            name="phone2prefix"
                                            error={Boolean(
                                                formik.touched.phone2prefix &&
                                                    formik.errors.phone2prefix
                                            )}
                                            helperText={
                                                formik.touched.phone2prefix &&
                                                formik.errors.phone2prefix
                                            }
                                            fullWidth
                                            onBlur={formik.handleChange}
                                            // onBlur={formik.handleBlur}
                                            // value={formik.values.phone2prefix}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <TextField
                                            variant="outlined"
                                            label={i18n.t('newcasoform.index.label.telefono2')}
                                            name="phone2"
                                            error={Boolean(
                                                formik.touched.phone2 &&
                                                    formik.errors.phone2
                                            )}
                                            helperText={
                                                formik.touched.phone2 &&
                                                formik.errors.phone2
                                            }
                                            fullWidth
                                            onBlur={formik.handleChange}
                                            // onBlur={formik.handleBlur}
                                            // value={formik.values.phone2}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <TextField
                                            variant="outlined"
                                            label={i18n.t('newcasoform.index.label.prefijo')}
                                            name="phone3prefix"
                                            error={Boolean(
                                                formik.touched.phone3prefix &&
                                                    formik.errors.phone3prefix
                                            )}
                                            helperText={
                                                formik.touched.phone3prefix &&
                                                formik.errors.phone3prefix
                                            }
                                            fullWidth
                                            onBlur={formik.handleChange}
                                            // onBlur={formik.handleBlur}
                                            // value={formik.values.phone3prefix}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <TextField
                                            variant="outlined"
                                            label={i18n.t('newcasoform.index.label.telefono3')}
                                            name="phone3"
                                            error={Boolean(
                                                formik.touched.phone3 &&
                                                    formik.errors.phone3
                                            )}
                                            helperText={
                                                formik.touched.phone3 &&
                                                formik.errors.phone3
                                            }
                                            fullWidth
                                            onBlur={formik.handleChange}
                                            // onBlur={formik.handleBlur}
                                            // value={formik.values.phone3}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Typography
                                            variant="title1"
                                            component="h3"
                                            color="primary"
                                        >
                                            Coberturas
                                        </Typography>
                                        {covError && (
                                            <Typography
                                                variant="error"
                                                color="error"
                                            >
                                            {i18n.t('newcasoform.index.label.camponumericoobligatorio')}
                                            </Typography>
                                        )}
                                    </Grid>

                                    {formik.values.coverages?.map(
                                        (coverage, index) => {
                                            return (
                                                <div
                                                    key={`coverage - ${index}`}
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        width: '100%',
                                                        marginBottom: 10,
                                                    }}
                                                >
                                                    <Grid item xs={9}>
                                                        <Typography
                                                            variant="body2"
                                                            color="primary"
                                                            component="span"
                                                            style={{
                                                                marginRight: 10,
                                                            }}
                                                        >
                                                            {coverage?.cbCode}
                                                        </Typography>
                                                        <Typography
                                                            variant="body1"
                                                            color="secondary"
                                                            component="span"
                                                        >
                                                            {coverage?.name}
                                                        </Typography>
                                                    </Grid>

                                                    <Grid
                                                        item
                                                        xs={3}
                                                        style={{
                                                            textAlign: 'right',
                                                            display: 'flex',
                                                            alignItems:
                                                                'center',
                                                        }}
                                                    >
                                                        <TextField
                                                            variant="outlined"
                                                            name={
                                                                coverage?.cbCode
                                                            }
                                                            fullWidth
                                                            onChange={
                                                                onChangeCapital
                                                            }
                                                            value={
                                                                coverage?.cbCapital
                                                            }
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            InputProps={{
                                                                inputProps: {
                                                                    min: 0,
                                                                    max: 10,
                                                                    style: {
                                                                        textAlign:
                                                                            'right',
                                                                        paddingRight: 10,
                                                                    },
                                                                },
                                                            }}
                                                        />
                                                        <span
                                                            style={{
                                                                marginLeft: 10,
                                                            }}
                                                        >
                                                            €
                                                        </span>
                                                        <sup>
                                                            {index === 0 && ((companyId != 21) ? '*' : '')}
                                                        </sup>
                                                    </Grid>
                                                </div>
                                            );
                                        }
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {superUser && (
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={() => {
                                formik.validateForm().then((validation) => {
                                    formik.setTouched(validation);

                                    if(formik.values.coverages.length !== 0) {
                                        if (
                                            formik.values.coverages[0].cbCapital === 0 && companyId != 21
                                        )
                                            setCovError(true);
                                        else {
                                            if (
                                                isNaN(
                                                    formik.values.coverages[0]
                                                        .cbCapital
                                                )
                                            ) {
                                                setCovError(true);
                                            } else {
                                                setCovError(false);
                                                !Object.keys(formik.errors)
                                                    .lenght &&
                                                    formik.handleSubmit();
                                            }
                                        }
                                    } else {
                                        setCovError(false);
                                        !Object.keys(formik.errors)
                                            .lenght &&
                                            formik.handleSubmit();
                                    }
                                    
                                });
                            }}
                        >
                            {i18n.t('newcasoform.index.label.guardarexpediente')}
                        </Button>
                    )}
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default NewCasoForm;
