import Cookies from "js-cookie";
import { LOGOUT_USER } from "../../../constants/actionTypes";
import axios from "axios";
import toast from "react-hot-toast";

const baseURL = process.env.REACT_APP_BACKEND_URL;

export const logout = () => (dispatch) => {
  axios.post(`${baseURL}v2/oauth/invalidate`)
    .then(res => {
        Cookies.remove('companyId');
        Cookies.remove('loginOK');
        Cookies.remove('userLog');
        Cookies.remove('role');
        dispatch({
          type: LOGOUT_USER,
        });
    }).catch((error) => {
      toast.error(`${error?.message}: invalid token logout`, {
        duration: 20000,
        style: {
            backgroundColor: '#EFEFEF',
            fontSize: '20px',
            fontWeight: 'bold',
        },
    });
  });
};
