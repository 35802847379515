import { Box, Button, Link, TextField, Typography, withStyles } from '@material-ui/core'
import React from 'react'
import logoTeladoc from '../../assets/images/icon.png'
import teladocIcon from '../../assets/images/teladocicon.png'
import i18n from '../../i18n';
import { sendEmailUserChangePass } from '../../services/changePassFunctions';
import "./styles.css"


const WIDTH_SCREEN_RESPONSIVE = 885;

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}
function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = React.useState(getWindowDimensions());

  React.useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

const ChangePasswordUsername = () => {
  const [username, setUsername] = React.useState("")
  const [loadSendEmailChangePass, setLoadSendEmailChangePass] = React.useState(false)
  const { width } = useWindowDimensions();

  const handleChangePassword = (e) => {
    e.preventDefault();
    setLoadSendEmailChangePass(true)
    sendEmailUserChangePass(username, setLoadSendEmailChangePass);
  }

  return (
    <Box component="div" sx={{marginTop: "-50px", height: "100vh", width: "100%"}}>
      <Box sx={{ height:40, backgroundColor: "#331f65", flex: 1 }} />
      <Box sx={{height:"calc(100vh - 40px)", display: "flex", flexDirection: "row"}}>
        {/* <h1 style={{color: "#331f65", position: "absolute", textAlign: "center", width: "50%", marginTop: "8%", fontFamily: "sans-serif", fontWeight: 500,letterSpacing: 1, fontSize: 32}}>{i18n.t('changePassword')}</h1> */}
        <Box sx={{display: "flex", flexDirection: "column", width: width >= WIDTH_SCREEN_RESPONSIVE ? "50%" : "100%", alignItems: "center", justifyContent: "center"}}>
          <Typography variant="h1" style={{ fontSize: width >= WIDTH_SCREEN_RESPONSIVE ? "31px" : "28px", 
            color: "#331f65",
            borderRadius: '15px 15px 0px 0px',
            borderColor: "#331f65",
            letterSpacing: 1,textAlign: "center", width: width >= WIDTH_SCREEN_RESPONSIVE ? "50%" : "85%", marginTop: "8%",
            padding: 20,
            marginTop: -40
          }}>
            {i18n.t('changePassword')}
          </Typography>
          <Box sx={{width: width >= WIDTH_SCREEN_RESPONSIVE ? "90%" : "100%", alignItems: "center", justifyContent: "center", display: "flex"}}>
            <form className='loginChangePass' onSubmit={handleChangePassword} style={{width: width >= WIDTH_SCREEN_RESPONSIVE ? "32%" : "60%"}}>
              <img src={teladocIcon} alt="logo porciplanet" width={150} style={{marginBottom: 25, marginTop: 20}}/>
              <TextField
                id="standard-search"
                label={i18n.t('pages.login.index.label.usuario')}
                type="text"
                name="username"
                onChange={e => setUsername(e.target.value)}
                value={username}
                style={{marginBottom: "40px"}}
              />
              <Button
                type="submit"
                autoFocus={true}
                color="primary"
                variant="contained"
                disabled={username === "" || loadSendEmailChangePass}
                disableElevation={false}
                disableFocusRipple={false}
                disableRipple={false}
              >
                { loadSendEmailChangePass ? <>{i18n.t('sendingButton')}</>
                  : <>{i18n.t('sendButton')}</>
                }
                
              </Button>
              {/* <StyledButton size="small" 
                style={{
                  fontSize: 13,
                  textTransform: 'none',
                  cursor: 'pointer',
                  marginTop: 20,
                }}
                disableElevation={false}
                disableFocusRipple={false}
                disableRipple={false}
                variant="text" color="primary"
                onClick={() => myContext.setChangePass(false)}
              >
                {i18n.t('backToLogin')}
              </StyledButton> */}
              <Link 
                  style={{
                      textAlign: "center",
                      fontSize: 13,
                      textTransform: 'none',
                      cursor: 'pointer',
                      padding: 20,
                      color: "#331f65",
                      fontWeight: "500",
                      letterSpacing: 0.5,
                  }}
                  underline="none"
                  href="/"
              >
                  {i18n.t('backToLogin')}
              </Link>
            </form> 
          </Box>
        </Box>
        
        { width >= WIDTH_SCREEN_RESPONSIVE ? <Box sx={{background: "#fff", width: "50%", alignItems: "center", justifyContent: "center", display: "flex", borderRadius: "50% 0 0 50%"}}>
         <img src={logoTeladoc} alt="logo porciplanet" width={360} style={{opacity: 0.85}}/> 
        </Box>
        :null
        }
      </Box>
    </Box>
  )
}

export default ChangePasswordUsername