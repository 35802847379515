import i18n from '../../i18n';

export const columns = [
    { label: i18n.t('casostable.columns.label.numsolicitud'), 
    id: 'numRequest', 
    align: 'center' 
    },
    {
        label: i18n.t('casostable.columns.label.numasegurado'),
        id: 'numInsured',
        align: 'center',
        minWidth: 150,
    },
    {
        label: i18n.t('casostable.columns.label.mediador'),
        id: 'mediadorCodigo',
        align: 'center',
        minWidth: 150,
    },
    {
        label: i18n.t('casostable.columns.label.fechaentrada'),
        id: 'entryDate',
        align: 'center',
        type: 'date',
        minWidth: 100,
    },
    { label: i18n.t('casostable.columns.label.nif'), 
    id: 'nif', 
    align: 'center' 
    },
    {
        label: i18n.t('casostable.columns.label.nombre'),
        id: 'name',
        align: 'center',
        minWidth: 300,
        cell: (row) => {
            return row.name + ' ' + row.surname1 + ' ' + row.surname2;
        },
    },
    {
        label: i18n.t('casostable.columns.label.fechaestado'),
        id: 'startStatusDate',
        align: 'center',
        minWidth: 100,
    },
    {
        label: i18n.t('casostable.columns.label.estado'),
        id: 'statusName',
        minWidth: 100,
    },
    {
        label: i18n.t('casostable.columns.label.subestado'),
        id: 'subStatusName',
        minWidth: 100,
    },
    {
        label: i18n.t('casostable.columns.label.fallecimiento'),
        id: 'coverage',
        minWidth: 150,
        cell: (row) => {
            let code;
            switch (row.company) {
                case 'Santalucía':
                    code = 'SantaLuciaCOB01';
                    break;
                case 'SVP TLC':
                    code = 'SVP_TLCCOB01';
                    break;
                case 'Pelayo':
                    code = 'PelayoCOB01';
                    break;
                case 'Unicorp':
                    code = 'UnicorpCOB01';
                    break;
                default:
                    break;
            }
            const fall = row.coverages.filter((element) => {
                return element.cbCode === code;
            })[0]?.cbCapital;
            return fall ? fall + i18n.t('casostable.columns.label.monedacoberturas') : '';
        },
    },

    { label: i18n.t('casostable.columns.label.idioma'), id: 'language', align: 'center', minWidth: 100 },
    { label: i18n.t('casostable.columns.label.provincia'), id: 'province', align: 'center', minWidth: 100 },
    { label: i18n.t('casostable.columns.label.poblacion'), id: 'city', align: 'center', minWidth: 100 },
    { label: i18n.t('casostable.columns.label.codigopostal'), id: 'postalCode', align: 'center', minWidth: 100 },
    { label: i18n.t('casostable.columns.label.domicilio'), id: 'address', align: 'center', minWidth: 100 },
    { label: i18n.t('casostable.columns.label.telefono1'), id: 'phone1', align: 'center', minWidth: 100 },
    { label: i18n.t('casostable.columns.label.telefono2'), id: 'phone2', align: 'center', minWidth: 100 },
    { label: i18n.t('casostable.columns.label.telefono3'), id: 'phone3', align: 'center', minWidth: 100 },
    {
        label: i18n.t('casostable.columns.label.pruebasoriginales'),
        id: 'originalTests',
        align: 'center',
        minWidth: 150,
        cell: (row) => {
            return row?.originalTests?.length;
        },
    },
    {
        label: i18n.t('casostable.columns.label.pruebasnecesarias'),
        id: 'neededTests',
        align: 'center',
        minWidth: 150,
        cell: (row) => {
            return row?.neededTests?.length;
        },
    },
    {
        label: i18n.t('casostable.columns.label.cita'),
        id: 'appointmentDate',
        align: 'center',
        type: 'date',
        minWidth: 100,
    },
    {
        label: i18n.t('casostable.columns.label.centromedico'),
        id: 'medicalCenter',
        align: 'center',
        type: 'date',
        minWidth: 100,
    },
    {
        label: i18n.t('casostable.columns.label.fechanacimiento'),
        id: 'birthDate',
        align: 'center',
        type: 'date',
        minWidth: 120,
    },
    {
        label: i18n.t('casostable.columns.label.sexo'),
        id: 'gender',
        align: 'center',
        type: 'date',
        minWidth: 50,
    },
];
