import React from "react";
import { TextField, Button, withStyles } from "@material-ui/core";
import "./login.scss";
import useForm from "./useForm";
import logo from "../../assets/images/logoteladoc.png";
import background from "../../assets/images/fondologin.png";
import { useEffect } from "react";
import toast from "react-hot-toast";
import i18n from '../../i18n';
import { MyContext } from "../../context/contextChangePass/contextChangePass";

const StyledButton = withStyles({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
  },
}})(Button);

const Login = () => {
  const { form, onChange, loading, error, loginFormValid, onSubmit } =
    useForm();

  const myContext = React.useContext(MyContext);

  useEffect(() => {
    if (error) {
      // console.log("PRUEBA ERROR: ", error)
      toast.error(error, {
        duration: 5000,
        style: {
          backgroundColor: "#EFEFEF",
          fontSize: "20px",
          fontWeight: "bold",
        },
      });
    }
  }, [error]);

  return (
    <div
      className="container-login"
      style={{ backgroundImage: `url(${background})` }}
    >
      <div className="login">
        <img src={logo} alt="teladoc" />
        <TextField
          id="standard-search"
          label={i18n.t('pages.login.index.label.usuario')}
          type="text"
          name="username"
          onChange={onChange}
          value={form.username || ""}
        />
        <TextField
          id="standard-password-input"
          label={i18n.t('pages.login.index.label.contrasenia')}
          type="password"
          name="password"
          autoComplete="current-password"
          onChange={onChange}
          value={form.password || ""}
        />
        <StyledButton size="small" 
          style={{
            fontSize: 13,
            textTransform: 'none',
            cursor: 'pointer',
            marginBottom: 12,
          }}
          disableElevation={false}
          disableFocusRipple={false}
          disableRipple={false}
          variant="text" color="primary"
          onClick={() => myContext.setChangePass(true)}
        >
          {i18n.t('forgotPassword')}
        </StyledButton>
        <Button
          type="submit"
          autoFocus={true}
          color="primary"
          variant="contained"
          onClick={onSubmit}
          disabled={loginFormValid || loading}
        >
          LOGIN
        </Button>
      </div>
    </div>
  );
};
export default Login;