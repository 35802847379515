import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';

import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import TableRow from '@material-ui/core/TableRow';
import './casosTable.scss';
import { IconButton } from '@material-ui/core';

import { colorAzul2, colorBlanco } from '../../constants/colors';
import { columns } from './columns';
import { useContext, useEffect } from 'react';
import { GlobalContext } from '../../context/Provider';
import {
    getCasos,
    selectCaso,
    getDetailSelectCaso,
} from '../../context/actions/casos/casos';
//import axiosInstance from '../../helpers/axiosInstance';
import i18n from '../../i18n';
import Cookies from 'js-cookie';

const CasosTable = () => {
    const {
        casosDispatch,
        casosState: {
            insureds,
            totalResults,
            pageable,
            search,
            newForm,
            totalInSearch,
        },
    } = useContext(GlobalContext);

    const companyId = Cookies.get('companyId');

    const handlePrevPage = () => {
        getCasos({ search, pageable: pageable - 1 })(casosDispatch);
    };
    const handleNextPage = () => {
        getCasos({ search, pageable: pageable + 1 })(casosDispatch);
    };

    const handleSelect = (selectedCaso) => () => {
        if (newForm.company.tuwVersion === 'G2') {
            getDetailSelectCaso(selectedCaso)(casosDispatch);
        } else {
            selectCaso(selectedCaso)(casosDispatch);
        }
    };

    const showCoverage =
        companyId === '6' ||
        companyId === '7' ||
        companyId === '8' ||
        companyId === '9';

    return (
        <Paper className="casos-table">
            <TableContainer className="container">
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => {
                                if (column.id === 'coverage') {
                                    if (!showCoverage) {
                                        return '';
                                    }
                                }
                                if (column.id === 'mediadorCodigo') {
                                    if (!showCoverage) {
                                        return '';
                                    }
                                }
                                return (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{
                                            minWidth: column.minWidth,
                                            backgroundColor: colorAzul2,
                                            color: colorBlanco,
                                        }}
                                    >
                                        {column.label}
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {insureds.length > 0 &&
                            insureds.map((row, index) => {
                                return (
                                    <TableRow
                                        key={`row-${index}`}
                                        hover
                                        role="checkbox"
                                        tabIndex={-1}
                                        onClick={handleSelect(row)}
                                    >
                                        {columns.map((column) => {
                                            const value = row[column.id];

                                            if (
                                                typeof column.cell ===
                                                'function'
                                            ) {
                                                if (column.id === 'coverage') {
                                                    if (!showCoverage) {
                                                        return '';
                                                    }
                                                }
                                                return (
                                                    <TableCell
                                                        key={column.id}
                                                        align={column.align}
                                                    >
                                                        {column.cell(row)}
                                                    </TableCell>
                                                );
                                            }
                                            if (
                                                column.id === 'mediadorCodigo'
                                            ) {
                                                if (!showCoverage) {
                                                    return '';
                                                }
                                            }
                                            return (
                                                <TableCell
                                                    key={column.id}
                                                    align={column.align}
                                                >
                                                    {column.format &&
                                                    typeof value === 'number'
                                                        ? column.format(value)
                                                        : value}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <div className="pagination">
                <div className="fill"></div>
                <div className="navigation">
                    <IconButton
                        disabled={pageable === 0}
                        aria-label={i18n.t('casostable.index.label.anterior')}
                        onClick={handlePrevPage}
                        size="small"
                    >
                        <NavigateBeforeIcon
                            color={pageable === 0 ? 'secondary' : 'primary'}
                        />
                    </IconButton>

                    <span>
                        {pageable + 1}{i18n.t('casostable.index.label.de')}{totalResults}
                    </span>
                    <IconButton
                        disabled={pageable === totalResults - 1}
                        aria-label={i18n.t('casostable.index.label.siguiente')}
                        onClick={handleNextPage}
                        size="small"
                        color="primary"
                    >
                        <NavigateNextIcon
                            color={
                                pageable === totalResults - 1
                                    ? 'secondary'
                                    : 'primary'
                            }
                        />
                    </IconButton>
                </div>
                <div className="results">{i18n.t('casostable.index.label.resultados')}{totalInSearch}</div>
            </div>
        </Paper>
    );
};

export default CasosTable;
