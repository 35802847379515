import i18n from '../../i18n';

export const fields = [
    { label: i18n.t('filters.fields.label.numsolicitud'), 
        id: 'numRequest', 
        align: 'center' },
    {
        label: i18n.t('filters.fields.label.numasegurado'),
        id: 'numInsured',
    },
    {
        label: i18n.t('filters.fields.label.mediador'),
        id: 'mediadorCodigo',
    },
    {
        label: i18n.t('filters.fields.label.fechainicial'),
        id: 'entryDate',
        type: 'date',
    },
    {
        label: i18n.t('filters.fields.label.fechafin'),
        id: 'closingDate',
        type: 'date',
    },
    {
        label: i18n.t('filters.fields.label.fechainicioestado'),
        id: 'startStatusDate',
        type: 'date',
    },
    {
        label: i18n.t('filters.fields.label.fechafinestado'),
        id: 'endStatusDate',
        type: 'date',
    },
    {
        label: i18n.t('filters.fields.label.estado'),
        id: 'statusId',
        type: 'combobox',
        options: 'statuslist',
    },
    {
        label: i18n.t('filters.fields.label.subestado'),
        id: 'subStatusId',
        type: 'combobox',
        options: 'substatuslist',
    },
    { label: i18n.t('filters.fields.label.nif'), id: 'nif' },
    { label: i18n.t('filters.fields.label.nombre'), id: 'name' },
    { label: i18n.t('filters.fields.label.apellido1'), id: 'surname1' },
    { label: i18n.t('filters.fields.label.apellido2'), id: 'surname2' },
    { label: i18n.t('filters.fields.label.telefono'), id: 'phone1', type: 'number', },
    { label: i18n.t('filters.fields.label.codigopostal'), id: 'postalCode', type: 'number' },
    { label: i18n.t('filters.fields.label.provincia'), id: 'province' },
    { label: i18n.t('filters.fields.label.idioma'), id: 'language', type: 'combobox', options: 'languages' },
];
