import { useEffect } from "react";
import { useContext } from "react";
import {
    changeSearch,
    clearSearch,
    getFiltersList,
} from "../../context/actions/casos/casos";
import { GlobalContext } from "../../context/Provider";
import Cookies from "js-cookie";

const useForm = () => {
    const {
        casosDispatch,
        authState: { user },
    } = useContext(GlobalContext);

    const onChange = (e) => {
        const target = e.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;
        const data = {
            [name]: value };
        changeSearch(data)(casosDispatch);
    };

    const onChangeCombo = (name, value) => {
        const data = {
            [name]: value };
        changeSearch(data)(casosDispatch);
    };

    const clearForm = () => {
        clearSearch(casosDispatch);
    };
    const setDateInForm = (name, value) => {
        let formatDate = new Date(value);
        let day = formatDate.getDate();
        let month = formatDate.getMonth() + 1;
        if (day < 10) {
            day = "0" + day;
        }
        if (month < 10) {
            month = "0" + month;
        }

        formatDate = day + "/" + month + "/" + formatDate.getFullYear();
        const data = {
            [name]: formatDate };
        changeSearch(data)(casosDispatch);
    };
    useEffect(() => {
        getFiltersList(casosDispatch);
    }, [casosDispatch]);
    let superUser = false;
    if (Cookies.get('role') === "AdminCA") {
        superUser = true;
    } else if (Cookies.get('role') === "AgenteCA") {
        superUser = true;
    } else if (Cookies.get('role') === 'AgenteSinSensiblesCA') {
        superUser = true;
    }
    return { setDateInForm, onChange, clearForm, onChangeCombo, superUser };
};

export default useForm;