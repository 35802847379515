import React, { useContext } from "react";
import { TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { GlobalContext } from "../../context/Provider";
import { useEffect } from "react";
import { getDocument, getDocuments, selectCaso } from "../../context/actions/casos/casos";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { makeStyles } from "@material-ui/core";
import axiosInstance from "../../helpers/axiosInstance";
import toast from "react-hot-toast";
import i18n from '../../i18n';
import {
  CASOS_ERROR,
  CASOS_LOADING,
  CASOS_REMOVE_LOADING,
} from "../../constants/actionTypes";
import { handleUnauthorizedError } from "../../services/Error401Service";
const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
}));
const DocumentsMenu = ({ canUpload }) => {
  const classes = useStyles();
  const {
    casosDispatch,
    casosState: { selected },
  } = useContext(GlobalContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorInformes, setAnchorInformes] = React.useState(null);
  const [nameInforme, setNameInforme] = React.useState();
  const [fileInforme, setFileInforme] = React.useState(null);
  const [nameDeclaracionInicial, setNameDeclaracionInicial] = React.useState();
  const [fileDeclaracionInicial, setFileDeclaracionInicial] =
    React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickInformes = (event) => {
    setAnchorInformes(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseDoc = () => {
    selectCaso(null)(casosDispatch);
  };

  const handleCloseInformes = () => {
    setAnchorInformes(null);
  };

  const handleGetDocument = (document) => {
    const data = {
      insured: {
        numRequest: selected.numRequest,
        numInsured: selected.numInsured,
      },
      document: {
        ...document,
      },
    };
    getDocument(data);
    setAnchorEl(null);
  };

  const handleChangeInforme = (e) => {
    const file = e.target.files[0];
    const fileName = file.name;
    setFileInforme(file);
    setNameInforme(fileName);
  };
  const handleChangeDeclaracionInicial = (e) => {
    const file = e.target.files[0];
    const fileName = file.name;
    setFileDeclaracionInicial(file);
    setNameDeclaracionInicial(fileName);
  };
  const handleAddFile = (documentTypeId) => {
    let file;
    let fileName;
    if (documentTypeId === 1) {
      file = fileDeclaracionInicial;
      fileName = nameDeclaracionInicial;
    } else if (documentTypeId === 2) {
      file = fileInforme;
      fileName = nameInforme;
    }
    casosDispatch({
      type: CASOS_LOADING,
    });
    var extension = file.name.match(/\.[0-9a-z]+$/i);
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      const data = {
        insured: {
          numRequest: selected.numRequest,
          numInsured: selected.numInsured,
          status: selected.status,
        },
        document: {
          fileName: fileName,
          fileType: extension[0],
          documentTypeId: documentTypeId,
          base64File: reader.result,
        },
      };
      axiosInstance()
        .post("/v1/modify/document", data)
        .then((res) => {
          if (res.data?.status === "ok") {
            toast.success(i18n.t('casoform.documents.toast.archivosubidook'), {
              duration: 20000,
              style: {
                backgroundColor: "#EFEFEF",
                fontSize: "20px",
                fontWeight: "bold",
              },
            });
            casosDispatch({
              type: CASOS_REMOVE_LOADING,
            });
            handleCloseDoc();
          }
        })
        .catch((err) => {
          console.log(err);
          handleUnauthorizedError(err);
          casosDispatch({
            type: CASOS_ERROR,
            payload: i18n.t('casoform.documents.toast.archivosubidoko') + err,
          });
        });
    };
    reader.onerror = function (error) {
      console.log(i18n.t('error'), error);
    };
  };
  useEffect(() => {
    if (!selected.documents) getDocuments(selected)(casosDispatch);
  }, [casosDispatch, selected]);

  return (
    <div className="document-buttons">
      <Button
        variant="contained"
        color="primary"
        disabled={!selected?.documents?.valoracion}
        onClick={() => handleGetDocument(selected?.documents?.valoracion)}
      >
        {i18n.t('casoform.documents.label.valoracion')}
      </Button>

      <Button
        variant="contained"
        color="primary"
        disabled={!selected?.documents?.cuestionario}
        onClick={() => handleGetDocument(selected?.documents?.cuestionario)}
      >
         {i18n.t('casoform.documents.label.cuestionario')}
      </Button>

      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        variant="contained"
        color="primary"
        disabled={!selected?.documents?.otherDocs?.length > 0}
        onClick={handleClick}
      >
        {i18n.t('casoform.documents.label.otrosdocumentos')}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {selected?.documents?.otherDocs?.length > 0 &&
          selected?.documents?.otherDocs?.map((document) => (
            <MenuItem onClick={() => handleGetDocument(document)}>
              {document.fileName}
            </MenuItem>
          ))}
      </Menu>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        variant="contained"
        color="primary"
        onClick={handleClickInformes}
        disabled={canUpload}
      >
        {i18n.t('casoform.documents.label.anadirdocumentos')}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorInformes}
        keepMounted
        open={Boolean(anchorInformes)}
        onClose={handleCloseInformes}
      >
        {!canUpload && selected?.statusId === "1" && (
          <MenuItem
            onClick={(e) => e.stopPropagation()}
            onKeyDown={(e) => e.stopPropagation()}
          >
            <input
              className={classes.input}
              id="declaracion-inicial"
              type="file"
              onChange={(e) => handleChangeDeclaracionInicial(e)}
            />
            {fileDeclaracionInicial && (
              <TextField
                size="small"
                variant="outlined"
                label={i18n.t('casoform.documents.label.nombredelficherodi')}
                type={"text"}
                onChange={(e) => setNameDeclaracionInicial(e.target.value)}
                value={nameDeclaracionInicial || ""}
                style={{ width: 195, marginRight: 15 }}
              />
            )}

            <label htmlFor="declaracion-inicial">
              <Button
                variant="contained"
                color="secondary"
                component="span"
                style={{ marginRight: 15 }}
              >
                <FileCopyIcon style={{ marginRight: 15 }} />
                {i18n.t('casoform.documents.label.seleccionardeclaracioninicial')}
              </Button>
            </label>

            {fileDeclaracionInicial && (
              <Button
                variant="contained"
                color="primary"
                component="span"
                onClick={() => handleAddFile(1)}
              >
                <FileCopyIcon style={{ marginRight: 15 }} />
                {i18n.t('casoform.documents.label.subirdeclaracioninicial')}
              </Button>
            )}
          </MenuItem>
        )}

        {!canUpload && (
          <MenuItem
            onClick={(e) => e.stopPropagation()}
            onKeyDown={(e) => e.stopPropagation()}
          >
            <input
              className={classes.input}
              id="informe"
              type="file"
              onChange={(e) => handleChangeInforme(e)}
            />
            {fileInforme && (
              <TextField
                size="small"
                variant="outlined"
                label={i18n.t('casoform.documents.label.nombredelficheroinforme')}
                type={"text"}
                onChange={(e) => setNameInforme(e.target.value)}
                value={nameInforme || ""}
                style={{ width: 195, marginRight: 15 }}
              />
            )}

            <label htmlFor="informe">
              <Button
                variant="contained"
                color="secondary"
                component="span"
                style={{ marginRight: 15 }}
              >
                <FileCopyIcon style={{ marginRight: 15 }} />
                {i18n.t('casoform.documents.label.seleccionarinforme')}
              </Button>
            </label>

            {fileInforme && (
              <Button
                variant="contained"
                color="primary"
                component="span"
                onClick={() => handleAddFile(2)}
              >
                <FileCopyIcon style={{ marginRight: 15 }} />
                {i18n.t('casoform.documents.label.subirinforme')}
              </Button>
            )}
          </MenuItem>
        )}
      </Menu>
    </div>
  );
};

export default DocumentsMenu;
