import { useEffect } from 'react';
import { useContext, useState } from 'react';
import { GlobalContext } from '../../context/Provider';
import * as Yup from 'yup';
import { useFormik } from 'formik';
/*import moment from 'moment';*/
import {
    CASOS_LOADING,
    CASOS_REMOVE_LOADING,
} from '../../constants/actionTypes';
import axiosInstance from '../../helpers/axiosInstance';
import toast from 'react-hot-toast';
import i18n from '../../i18n';
import Cookies from 'js-cookie';
import { handleUnauthorizedError } from '../../services/Error401Service';

const useForm = (handleCloseForm) => {
    const {
        casosState: { newForm },
        casosDispatch,
        authState: { user },
    } = useContext(GlobalContext);
    
    const companyId = Cookies.get('companyId');

    const [form, setForm] = useState();

    const showCoverage =
        companyId === '6' ||
        companyId === '7' ||
        companyId === '8' ||
        companyId === '9';

    const hideNumRequest =
       companyId === '7';

    const formik = useFormik({
        initialValues: {
            coverages: newForm?.insured?.coverages
                ? newForm?.insured?.coverages
                : [],
            numRequest: hideNumRequest ? '0' : '',
            mediadorCodigo: showCoverage ? '' : '0',
            numInsured: '',
            gender: '',
            name: '',
            surname1: '',
            surname2: '',
            nif: '',
            birthDate: null,
            language: '',
            contactHours: '',
            phone1prefix: '',
            phone1: '',
            address: '',
            postalCode: '',
            city: '',
            province: '',
            phone2prefix: '',
            phone2: '',
            phone3prefix: '',
            phone3: '',
            observations: '',
        },
        validationSchema: Yup.object().shape(
            {
                // numRequest: Yup.string().matches(/[^d{0}*]/g, i18n.t('newcasoform.useform.yuprequired.notallzeros'))
                // .matches(/^[A-Za-z0-9]+$/g, i18n.t('newcasoform.useform.yuprequired.notblankspaces'))
                // .required(i18n.t('newcasoform.useform.yuprequired.campoobligatorio')),
                numRequest: Yup.string().required(i18n.t('newcasoform.useform.yuprequired.campoobligatorio')),
                numInsured: Yup.string()
                    .matches(/[^d{0}*]/g, i18n.t('newcasoform.useform.yuprequired.notallzeros'))
                    .matches(/^[1-9][0-9]*/, i18n.t('newcasoform.useform.matches.numinsured'))
                    .matches(/^[A-Za-z0-9]+$/g, i18n.t('newcasoform.useform.yuprequired.notblankspaces'))
                    .required(i18n.t('newcasoform.useform.yuprequired.campoobligatorio')),
                mediadorCodigo: Yup.string().required(i18n.t('newcasoform.useform.yuprequired.campoobligatorio')),
                gender: Yup.string().required(i18n.t('newcasoform.useform.yuprequired.campoobligatorio')),
                language: Yup.string().required(i18n.t('newcasoform.useform.yuprequired.campoobligatorio')),
                contactHours: Yup.string().required(i18n.t('newcasoform.useform.yuprequired.campoobligatorio')),
                name: Yup.string().required(i18n.t('newcasoform.useform.yuprequired.campoobligatorio')),
                surname1: Yup.string().required(i18n.t('newcasoform.useform.yuprequired.campoobligatorio')),
                // surname2: Yup.string().required(i18n.t('newcasoform.useform.yuprequired.campoobligatorio')),
                nif: Yup.string().required(i18n.t('newcasoform.useform.yuprequired.campoobligatorio')),
                birthDate: Yup.string().required(i18n.t('newcasoform.useform.yuprequired.campoobligatorio')),
                phone1: Yup.number()
                    .typeError(i18n.t('newcasoform.useform.yuprequired.camponumero'))
                    .required(i18n.t('newcasoform.useform.yuprequired.campoobligatorio')),
                phone1prefix: Yup.number()
                    .typeError(i18n.t('newcasoform.useform.yuprequired.camponumero'))
                    .required(i18n.t('newcasoform.useform.yuprequired.campoobligatorio')),
                phone2: Yup.number().typeError(i18n.t('newcasoform.useform.yuprequired.camponumero')),
                phone2prefix: Yup.number().typeError(i18n.t('newcasoform.useform.yuprequired.camponumero')),
                phone3: Yup.number().typeError(i18n.t('newcasoform.useform.yuprequired.camponumero')),
                phone3prefix: Yup.number().typeError(i18n.t('newcasoform.useform.yuprequired.camponumero')),
                city: Yup.string().when('postalCode', (postalCode) => {
                    if (postalCode)
                        return Yup.string().required(i18n.t('newcasoform.useform.yuprequired.campoobligatorio'));
                }),
                postalCode: Yup.number()
                    .typeError(i18n.t('newcasoform.useform.yuprequired.camponumero'))
                    .when('city', (city) => {
                        if (city)
                            return Yup.number()
                                .typeError(i18n.t('newcasoform.useform.yuprequired.camponumero'))
                                .required(i18n.t('newcasoform.useform.yuprequired.campoobligatorio'));
                    }),
            },
            [['postalCode', 'city']]
        ),
        onSubmit: async (values, { resetForm, setFieldValue }) => {
            values.birthDate = setDateInForm(values.birthDate);
            // console.log("Values:", values);
            casosDispatch({
                type: CASOS_LOADING,
            });
            axiosInstance()
                .post('/v1/load/form', formik.values)
                .then((res) => {
                    if (res.data?.status === 'ok') {
                        toast.success(i18n.t('newcasoform.useform.toast.solicitudcreada'), {
                            duration: 20000,
                            style: {
                                backgroundColor: '#EFEFEF',
                                fontSize: '20px',
                                fontWeight: 'bold',
                            },
                        });
                        resetForm();
                        handleCloseForm();
                        setFieldValue('coverages', newForm?.insured?.coverages);
                        casosDispatch({
                            type: CASOS_REMOVE_LOADING,
                        });
                        clearForm();
                    } else {
                        toast.error(
                            i18n.t('newcasoform.useform.toast.errorcrearsolicitud') + res.data?.message,
                            {
                                duration: 20000,
                                style: {
                                    backgroundColor: '#EFEFEF',
                                    fontSize: '20px',
                                    fontWeight: 'bold',
                                },
                            }
                        );
                        casosDispatch({
                            type: CASOS_REMOVE_LOADING,
                        });
                    }
                })
                .catch((err) => {
                    handleUnauthorizedError(err);
                    toast.error(err, {
                        duration: 20000,
                        style: {
                            backgroundColor: '#EFEFEF',
                            fontSize: '20px',
                            fontWeight: 'bold',
                        },
                    });
                    casosDispatch({
                        type: CASOS_REMOVE_LOADING,
                    });
                });
        },
    });

    const setDateInForm = (value) => {
        let formatDate = new Date(value);
        formatDate =
            formatDate.getDate() +
            '/' +
            (formatDate.getMonth() + 1) +
            '/' +
            formatDate.getFullYear();
        return formatDate;
    };

    const setNumRequest = (value) => {
        setForm({ ...form, numRequest: value });
    };

    const setMediadorCodigo = (value) => {
        setForm({ ...form, mediadorCodigo: value });
    };

    const onChangeCapital = (e) => {
        const target = e.target;
        const value =
            target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        const newCoverages = formik.values.coverages.map((p) =>
            p.cbCode === name ? { ...p, cbCapital: value } : p
        );
        formik.setFieldValue('coverages', newCoverages);
    };

    const clearForm = () => {
        setForm({ coverages: newForm?.insured?.coverages });
    };

    let superUser = false;
    if (Cookies.get('role') === 'AdminCA') {
        superUser = true;
    } else if (Cookies.get('role') === 'AgenteCA') {
        superUser = true;
    } else if (Cookies.get('role') === 'AgenteSinSensiblesCA') {
        superUser = true;
    }

    useEffect(() => {
        if (newForm?.insured?.coverages)
            formik.setFieldValue('coverages', newForm?.insured?.coverages);
    }, [newForm]);

    return {
        form,
        superUser,
        onChangeCapital,
        clearForm,
        setMediadorCodigo,
        setNumRequest,
        formik,
        setDateInForm,
    };
};

export default useForm;
