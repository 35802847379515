import React, { createContext, useReducer } from "react";
import auth from "./reducers/auth";
import authInitialState from "./intialstates/authInitialState";
import casos from "./reducers/casos";
import casosInitialState from "./intialstates/casosInitialState";
import Cookies from "js-cookie";

export const GlobalContext = createContext({loginOK: false,
  setLoginOK: () => {},
});

export const GlobalProvider = ({ children }) => {
  const [authState, authDispatch] = useReducer(auth, authInitialState);
  const [casosState, casosDispatch] = useReducer(casos, casosInitialState);

  const initialLoginOK = Cookies.get("loginOK") === "false";
  const [loginOK, setLoginOK] = React.useState(initialLoginOK);

  const handleSetLoginOK = (value) => {
    setLoginOK(value);
  };

  return (
    <GlobalContext.Provider
      value={{
        authState,
        authDispatch,
        casosState,
        casosDispatch,
        loginOK, 
        setLoginOK: handleSetLoginOK
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
