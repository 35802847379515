const casosInit = {
  loading: false,
  insureds: [],
  orderASCorDESC: "DESC",
  orderBy: "numRequest",
  pageable: 0,
  resultsPerPage: 0,
  search: {
    pageable: 0,
    orderBy: "numRequest",
    orderASCorDESC: "DESC",
  },
  filterList: {},
  newform: null,
  totalResults: 0,
  error: null,
  selected: null,
};

export default casosInit;
