import axios from 'axios'
import { toast } from 'react-hot-toast';
import axiosInstance from '../helpers/axiosInstance';
import i18n from '../i18n';

const baseURL = process.env.REACT_APP_BACKEND_URL;


// Resquestmail
export const sendEmailUserChangePass = async(username, setLoadSendEmailChangePass) => {
    axiosInstance().post(`/v1/forgotpwd/requestmail`, {
            "username": username
        }).then(res => {
            setLoadSendEmailChangePass(false)
        if(res?.data?.status === "ERROR") {
            toast.error(`ERROR: ${res?.data?.message}`, {
            duration: 10000,
            style: {
                backgroundColor: '#EFEFEF',
                fontSize: '20px',
                fontWeight: 'bold',
            },
            });
        } else {
            setLoadSendEmailChangePass(false)
            toast.success(i18n.t('notificationUserChangePass'), {
            duration: 20000,
            style: {
                backgroundColor: '#EFEFEF',
                fontSize: '20px',
                fontWeight: 'bold',
            },
            });
        }
        }).catch (error => {
            setLoadSendEmailChangePass(false)
            toast.error(`ERROR: ${error}`, {
                duration: 20000,
                style: {
                    backgroundColor: '#EFEFEF',
                    fontSize: '20px',
                    fontWeight: 'bold',
                },
            });
        })
}

// validateToken
export const validateTokenChangePass = async(tokenUUID) => {
    const res = await axios.post(`${baseURL}v1/forgotpwd/validatetoken`, {
            "username": "",
            "userMail": "",
            "tokenUUID": tokenUUID
        })
    return res.data;
}

// changeit
export const changePass = async(tokenUUID, newPassword, setLoadSendEmailChangePass) => {
    const res = await axios.post(`${baseURL}v1/forgotpwd/changeit`, {
            "tokenUUID": tokenUUID,
            "password": newPassword
        })
    setLoadSendEmailChangePass(false)
    return res.data
}