import i18n from '../../i18n';

export const genders = [
  {
    value: "H",
    label: i18n.t("casoform.constants.genders.hombre"),
  },
  {
    value: "1",
    label: i18n.t("casoform.constants.genders.hombre"),
  },
  {
    value: "M",
    label: i18n.t("casoform.constants.genders.mujer"),
  },
  {
    value: "2",
    label: i18n.t("casoform.constants.genders.mujer"),
  },
];

export const horarios = [
  {
    value: "M",
    label: i18n.t("casoform.constants.horarios.maniana"),
  },
  {
    value: "T",
    label: i18n.t("casoform.constants.horarios.tarde"),
  },
  {
    value: "I",
    label: i18n.t("casoform.constants.horarios.indiferente"),
  },

  {
    value: "1",
    label: i18n.t("casoform.constants.horarios.indiferente"),
  },
    {
    value: "2", 
    label: i18n.t("casoform.constants.horarios.manianahh"),
  },
  {
    value: "3", 
    label: i18n.t("casoform.constants.horarios.mediodiashh"),
  },
  {
    value: "4", 
    label: i18n.t("casoform.constants.horarios.tardeshh"),
  },
  {
    value: "5", 
    label: i18n.t("casoform.constants.horarios.nocheshh"),
  },
  {
    value: "6", 
    label: i18n.t("casoform.constants.horarios.primerahorahh"),
  },
];
