import axiosInstance from '../../../helpers/axiosInstance';
import qs from 'qs';
import {
    LOGIN_LOADING,
    LOGIN_SUCCESS,
    LOGIN_ERROR,
} from '../../../constants/actionTypes';
import jwt_decode from 'jwt-decode';
import { logout } from './logout';
import i18n from '../../../i18n';
import Cookies from 'js-cookie';

const cookieOptions = {
    expires: 1,
    // httpOnly: true, 
    secure: true
};

export const login =
    ({ password, username, setLoginOK }) =>
    (dispatch) => {
        dispatch({
            type: LOGIN_LOADING,
        });

        var data = qs.stringify({
            grant_type: 'password',
            username: username,
            password: password,
        });
        


        axiosInstance(true)
            .post('/v2/oauth/token', data)
            .then((res) => {
                let userLog = jwt_decode(res.data?.access_token)?.sub
                if (res.data?.access_token) {
                    setLoginOK(true)
                    Cookies.set('loginOK', true, {...cookieOptions, expires: res?.data?.expires_in ? (res?.data?.expires_in / 86400) : cookieOptions.expires});
                    Cookies.set('userLog', userLog, {...cookieOptions, expires: res?.data?.expires_in ? (res?.data?.expires_in / 86400) : cookieOptions.expires});
                    Cookies.set('role', res?.data?.role, {...cookieOptions, expires: res?.data?.expires_in ? (res?.data?.expires_in / 86400) : cookieOptions.expires});

                    dispatch({
                        type: LOGIN_SUCCESS,
                        payload: jwt_decode(res.data?.access_token),
                    });
                    axiosInstance()
                        .post('/v1/utilities/company/info')
                        .then((res) => {
                            if (res.data?.company?.companyId) {
                                Cookies.set('companyId', res.data?.company?.companyId, {...cookieOptions, expires: res?.data?.expires_in ? (res?.data?.expires_in / 86400) : cookieOptions.expires});
                            } else {
                                console.log(res?.data?.message);
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                } else {
                    dispatch({
                        type: LOGIN_ERROR,
                        payload: i18n.t('context.actions.auth.login.toast.respuestaincorrecta'),
                    });
                }
            })
            .catch((err) => {
                if (err?.response === undefined) {
                    dispatch({
                        type: LOGIN_ERROR,
                        payload: i18n.t('context.actions.auth.login.toast.nocomunicacion'),
                    });
                } else {
                    dispatch({
                        type: LOGIN_ERROR,
                        payload: err?.response?.data?.error_description,
                    });
                }
            });
    };

export const loginWithToken = () => (authState, dispatch) => {
    const { user } = authState;
    if (user) {
        dispatch({
            type: LOGIN_LOADING,
        });

        const user = jwt_decode(user);
        if (Date.now() >= user.exp * 1000) {
            logout()(dispatch);
        } else {
            dispatch({
                type: LOGIN_SUCCESS,
                payload: user,
            });
        }
    }
};
