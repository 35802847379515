import { Button } from '@material-ui/core';
import { useContext, useEffect } from 'react';
import { logout } from '../../context/actions/auth/logout';
import { GlobalContext } from '../../context/Provider';
import './menu.scss';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { logoutCasos } from '../../context/actions/casos/casos';
import PersonIcon from '@material-ui/icons/Person';
import axiosInstance from '../../helpers/axiosInstance';
import i18n from '../../i18n';
import Cookies from 'js-cookie';

const Menu = () => {
    const {
        authDispatch,
        casosDispatch,
        authState,
        casosState: { newForm },
        setLoginOK
    } = useContext(GlobalContext);
    const handleUserLogout = () => {
        logout({setLoginOK})(authDispatch);
        logoutCasos()(casosDispatch);
    };

    return (
        <nav className="menu">
            <div className="company">{newForm?.company?.nombreCompania}</div>
            <ul>
                <li className="user">
                    <PersonIcon />
                    {authState?.user?.user_name || Cookies.get('userLog')}{' '}
                </li>
                <li>
                    <Button
                        color="secondary"
                        size="small"
                        variant="contained"
                        onClick={handleUserLogout}
                        startIcon={<ExitToAppIcon />}
                    >
                        {i18n.t('menu.index.label.salir')}
                    </Button>
                </li>
            </ul>
        </nav>
    );
};

export default Menu;