export function isNumber(val) {
    return /^[-]?\d+$/.test(val);
}

export function gotNumber(pass) {
    let gotNumberResponse = false;
    pass.split('').some(letter => {
        if(isNumber(letter)) {
            gotNumberResponse = true;
            return true;
        } return false
    });

    return gotNumberResponse;
};

export function gotUpperCaseLetterMin(pass) {
    let passwordNewLower = pass.toLowerCase()
    if(passwordNewLower == pass) return false
    else return true
};

export function gotLowerCaseLetterMin(pass) {
    let passwordNewUpper = pass.toUpperCase()
    if(passwordNewUpper == pass) return false
    else return true
};

export function gotSpecialCharacterMin1(pass) {
    let caracteres = "!$%&/()=¿¡?'_:;,|@#€*+.";
    const filteredArray = pass.split('').filter(letter => caracteres.includes(letter));

    return (filteredArray.length > 0);
}

// Generate password aleatory
export function generateP() {
    const str = "ABCDEFGHIJKLMNOPQRSTUVWXYZ" + 
            "abcdefghijklmnopqrstuvwxyz0123456789!$%&/()=¿¡?'_:;,|@#€*+.";   
    let pass = "";
    do{
        pass = "";
        for (let i = 1; i <= 8; i++) {
            var char = Math.floor(Math.random()
                        * str.length + 1);
            
            pass += str.charAt(char)
        }
    } while(!gotNumber(pass) || !gotUpperCaseLetterMin(pass) || !gotSpecialCharacterMin1(pass) || !gotLowerCaseLetterMin(pass) || pass.length < 8)
      
    return pass;
}