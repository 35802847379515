import { MyContextProvider } from "./context/contextChangePass/contextChangePass";
import { GlobalProvider } from "./context/Provider";

import Layout from "./pages/Layout";

function App() {
  return (
    <MyContextProvider>
      <GlobalProvider>
        <Layout />
      </GlobalProvider>
    </MyContextProvider>
  );
}

export default App;
