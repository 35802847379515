import axiosInstance from '../../../helpers/axiosInstance';
import {
    CASOS_LOADING,
    CASOS_SUCCESS,
    CASOS_ERROR,
    CASO_SELECTED,
    CASO_NEW,
    CHANGE_SEARCH,
    CLEAR_SEARCH,
    FILTERS_LIST,
    CASO_SELECTED_DOCUMENTS,
    CHANGE_PAGE,
    CASOS_REMOVE_LOADING,
    LOGOUT_USER,
} from '../../../constants/actionTypes';
import toast from 'react-hot-toast';
import i18n from '../../../i18n';
import { handleUnauthorizedError } from '../../../services/Error401Service';

export const getCasos =
    ({ search, pageable = 0 }) =>
    (dispatch) => {
        dispatch({
            type: CASOS_LOADING,
        });

        var data = {
            search: { ...search, pageable: pageable },
            pageable,
        };

        axiosInstance()
            .post('/v1/obtain/insureds', data)
            .then((res) => {
                if (res.data?.insureds) {
                    dispatch({
                        type: CASOS_SUCCESS,
                        payload: res?.data?.insureds,
                    });
                } 
                else {
                //      dispatch({
                //          type: CASOS_ERROR,
                //          payload: i18n.t('error') + res?.data?.message,
                //      });
                // //     localStorage.setItem('token', '');

                toast.error(i18n.t('error') + res?.data?.message, {
                    duration: 10000,
                    style: {
                        backgroundColor: '#EFEFEF',
                        fontSize: '20px',
                        fontWeight: 'bold',
                    },
                });
                dispatch({
                    type: CASOS_REMOVE_LOADING,
                });
                }
            }
            )
            .catch((err) => {
                handleUnauthorizedError(err);
                toast.error(i18n.t('context.actions.casos.toast.errorobtaininsureds') + err, {
                    duration: 20000,
                    style: {
                        backgroundColor: '#EFEFEF',
                        fontSize: '20px',
                        fontWeight: 'bold',
                    },
                });
                dispatch({
                    type: CASOS_ERROR,
                    payload: i18n.t('context.actions.casos.toast.errorobtaininsureds') + err,
                });
            });
    };

export const selectCaso = (caso) => (dispatch) => {
    dispatch({
        type: CASO_SELECTED,
        payload: caso,
    });
};

export const getDetailSelectCaso =
    ({ numRequest, numInsured }) =>
    (dispatch) => {
        dispatch({
            type: CASOS_LOADING,
        });
        const data = {
            insured: {
                numRequest,
                numInsured,
            },
        };
        axiosInstance()
            .post('/v1/obtain/insureds/detail', data)
            .then((res) => {
                if (res.data?.status === 'ok') {
                    dispatch({
                        type: CASO_SELECTED,
                        payload: res?.data?.insureds,
                    });
                } else {
                    dispatch({
                        type: CASOS_ERROR,
                        payload: 'ERROR',
                    });
                }
            })
            .catch((err) => {
                handleUnauthorizedError(err);
                // console.log(err);
                dispatch({
                    type: CASOS_ERROR,
                    payload: i18n.t('context.actions.casos.toast.errorinsuredsdetail') + err,
                });
            });
    };

export const exportCaso = (search) => (dispatch) => {
    dispatch({
        type: CASOS_LOADING,
    });
    const data = {
        search,
    };
    axiosInstance()
        .post('/v1/obtain/export/file', data)
        .then((res) => {
            if (res.data?.status === 'ok') {
                dispatch({
                    type: CASOS_REMOVE_LOADING,
                });
                const linkSource =
                    'data:text/csv;base64,' + res.data.document.base64File;
                const downloadLink = document.createElement('a');
                downloadLink.href = linkSource;
                downloadLink.download = res.data.document.fileName;
                downloadLink.click();
            }
        })
        .catch((err) => {
            handleUnauthorizedError(err);
            // console.log(err);
            dispatch({
                type: CASOS_ERROR,
                payload: i18n.t('context.actions.casos.toast.errorexportfile') + err,
            });
        });
};

export const getDocuments =
    ({ numRequest, numInsured, statusId }) =>
    (dispatch) => {
        dispatch({
            type: CASOS_LOADING,
        });
        const data = {
            insured: {
                numRequest,
                numInsured,
                statusId,
            },
        };
        axiosInstance()
            .post('/v1/obtain/documentlist', data)
            .then((res) => {
                if (res.data?.status === 'ok') {
                    dispatch({
                        type: CASO_SELECTED_DOCUMENTS,
                        payload: res.data?.documents,
                    });
                    dispatch({
                        type: CASOS_REMOVE_LOADING,
                    });
                } else {
                    // console.log('ERROR');
                    dispatch({
                        type: CASOS_ERROR,
                        payload: 'ERROR',
                    });
                }
            })
            .catch((err) => {
                handleUnauthorizedError(err);
                // console.log(err);
                dispatch({
                    type: CASOS_ERROR,
                    payload: i18n.t('context.actions.casos.toast.errorobtaindocumentlist') + err,
                });
            });
    };

export const getDocument = (data) => {
    axiosInstance()
        .post('/v1/obtain/document/base64', data)
        .then((res) => {
            if (res.data?.status === 'ok') {
                const linkSource =
                    'data:' +
                    res.data.document.fileType +
                    ';base64,' +
                    res.data.document.base64File;
                const downloadLink = document.createElement('a');
                downloadLink.href = linkSource;
                downloadLink.download = res.data.document.fileName;
                downloadLink.click();
            }
        })
        .catch((err) => {
            handleUnauthorizedError(err);
            console.log(err);
        });
};

export const getFormsFields = (dispatch) => {
    axiosInstance()
        .post('/v1/utilities/form', {})
        .then((res) => {
            if (res.data?.status === 'ok') {
                dispatch({
                    type: CASO_NEW,
                    payload: res.data,
                });
            } else {
                // console.log('ERROR');
                dispatch({
                    type: CASOS_ERROR,
                    payload: 'ERROR',
                });
            }
        })
        .catch((err) => {
            handleUnauthorizedError(err);
            console.log(err);
            dispatch({
                type: CASOS_ERROR,
                payload: i18n.t('context.actions.casos.toast.errorutilitiesform') + err,
            });
        });
};

export const getFiltersList = (dispatch) => {
    axiosInstance()
        .post('/v1/utilities/inboxform', {})
        .then((res) => {
            if (res.data?.status === 'ok') {
                dispatch({
                    type: FILTERS_LIST,
                    payload: res.data,
                });
                getFormsFields(dispatch);
            } else {
                console.log('ERROR');
                dispatch({
                    type: CASOS_ERROR,
                    payload: 'ERROR',
                });
            }
        })
        .catch((err) => {
            handleUnauthorizedError(err);
            console.log(err);
            dispatch({
                type: CASOS_ERROR,
                payload: i18n.t('context.actions.casos.toast.errorutilitiesinboxform') + err,
            });
        });

    // dispatch({
    //   type: FILTERS_LIST,
    //   payload: inboxformmock,
    // });
};

export const changeSearch = (payload) => (dispatch) => {
    dispatch({
        type: CHANGE_SEARCH,
        payload,
    });
};

export const clearSearch = (dispatch) => {
    dispatch({
        type: CLEAR_SEARCH,
    });
};

export const changePage = (payload) => (dispatch) => {
    dispatch({
        type: CHANGE_PAGE,
        payload,
    });
};

export const newCaso = (dispatch) => {
    axiosInstance()
        .post('/v1/utilities/form', {})
        .then((res) => {
            if (res.data?.status === 'ok') {
                dispatch({
                    type: CASO_NEW,
                    payload: res.data,
                });
            } else {
                console.log('ERROR');
                dispatch({
                    type: CASOS_ERROR,
                    payload: 'ERROR',
                });
            }
        })
        .catch((err) => {
            handleUnauthorizedError(err);
            console.log(err);
            dispatch({
                type: CASOS_ERROR,
                payload: i18n.t('context.actions.casos.toast.errorutilitiesform') + err,
            });
        });
};

export const closeNewCaso = (dispatch) => {
    dispatch({
        type: CASO_NEW,
        payload: null,
    });
};

export const logoutCasos = () => (dispatch) => {
    dispatch({
        type: LOGOUT_USER,
    });
};
