import React from 'react'
import { Button, TextField } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import { es } from 'date-fns/locale';
import './filters.scss';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import PublishIcon from '@material-ui/icons/Publish';

import useForm from './useForm';
import { fields } from './fields';
import { useContext, useEffect } from 'react';
import { GlobalContext } from '../../context/Provider';
import { exportCaso, getCasos } from '../../context/actions/casos/casos';
import Autocomplete from '@material-ui/lab/Autocomplete';
import toast from 'react-hot-toast';
import moment from 'moment/min/moment-with-locales';
import i18n from '../../i18n';
import Cookies from 'js-cookie';

const returnOptions = (optionsData) => {

    let options = [];
    if (optionsData) {
        Object.entries(optionsData).forEach((entry) => {
            const [key, value] = entry;
            options.push({ id: key, title: value });
        });
        return options.sort((a, b) => (a.title > b.title && 1) || -1);
    }
};
export const convertDate = (date) => {
    if (date) {
        const dateParts = date.split('/');
        return new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);
    }
    return null;
};

const Filters = ({ handleOpenCarga, handleOpenForm }) => {

    const [selectedStateComboBox, setSelectedStateComboBox] = React.useState(null);
    const {
        casosDispatch,
        casosState: { search, filterList },
        authState: { user },
    } = useContext(GlobalContext);
    
    const companyId = Cookies.get('companyId');

    const { onChange, clearForm, setDateInForm, onChangeCombo, superUser } =
        useForm();

    const handleSearch = () => {
        getCasos({ search, pageable: 0 })(casosDispatch);
    };

    const showCoverage =
        companyId === '6' ||
        companyId === '7' ||
        companyId === '8' ||
        companyId === '9';

    const handleNewCaso = () => {
        handleOpenForm();
        toast.success(
            i18n.t('filters.index.toast.infopruebasmedicas'),
            {
                duration: 5000,
                style: {
                    backgroundColor: '#c7d8e9',
                    border: '1px solid #713200',
                    padding: '16px',
                    color: '#003468',
                    fontWeight: 'bold',
                },
                icon: '',
                position: 'top-center',
            }
        );
    };

    const getSubstatusListWithstatusSelected = (substateListNew, selectedState) => {
        // console.log("list new substates function: ", substateListNew)
        // console.log("state selected: ", selectedState)

    //   const filterSelectedStatesublist = substateListNew["2,4,10,11,17,24"]
        const filterSelectedStatesublist = substateListNew[selectedState.id]
        // console.log("filerSelectedsublist:", filterSelectedStatesublist)
        return filterSelectedStatesublist;
    }

    return (
        <div className="filters">
            <div className="fields">
                {fields.map((filter, index) => {
                    if (filter.type === 'date') {
                        return (
                            <MuiPickersUtilsProvider
                                locale={es}
                                utils={DateFnsUtils}
                                key={filter.id}
                            >
                                <KeyboardDatePicker
                                    variant="inline"
                                    size="small"
                                    label={filter.label}
                                    value={
                                        convertDate(search[filter.id]) || null
                                    }
                                    onChange={(value) => {
                                        setDateInForm(filter.id, value);
                                    }}
                                    minDate={
                                        filter.id === 'closingDate'
                                            ? moment(
                                                  search.entryDate,
                                                  'DD/MM/YYYY'
                                              ).format('YYYY-MM-DD')
                                            : filter.id === 'endStatusDate'
                                            ? moment(
                                                  search.startStatusDate,
                                                  'DD/MM/YYYY'
                                              ).format('YYYY-MM-DD')
                                            : moment('1900-01-01').format(
                                                  'YYYY-MM-DD'
                                              )
                                    }
                                    maxDate={
                                        filter.id === 'entryDate'
                                            ? moment(
                                                  search.closingDate,
                                                  'DD/MM/YYYY'
                                              ).format('YYYY-MM-DD')
                                            : filter.id === 'startStatusDate'
                                            ? moment(
                                                  search.endStatusDate,
                                                  'DD/MM/YYYY'
                                              ).format('YYYY-MM-DD')
                                            : moment('2500-01-01').format(
                                                  'YYYY-MM-DD'
                                              )
                                    }
                                    inputVariant="outlined"
                                    format="dd/MM/yyyy"
                                    style={{ width: 195 }}
                                />
                            </MuiPickersUtilsProvider>
                        );
                    } else if (filter.type === 'combobox') {
                        let options  = null;
                        if(selectedStateComboBox !== null && filter.id === "subStatusId"){
                            // console.log("cargando subestados")
                            // console.log("Filter options:", filter.options)
                            // console.log("Filter list new:",filterList["substatuscomplexlist"])

                            const filterSelectedStateSublist = getSubstatusListWithstatusSelected(filterList["substatuscomplexlist"], selectedStateComboBox);
                            options = returnOptions(filterSelectedStateSublist) || [];
                        } else {
                            options  = returnOptions(filterList[filter.options]) || [];
                        }
                      
                        const optionSelected = options?.find(
                            (option) => option.id === search[filter.id]
                        );

                        return (
                            <Autocomplete
                                id={filter.id}
                                key={filter.id}
                                name={filter.id}
                                value={optionSelected || null}
                                onChange={(event, newValue) => {
                                    if (newValue) {
                                        if(filter.id ==="statusId") {
                                            // console.log("filter id: ", filter.id)
                                            // console.log("change status")
                                            // console.log("Option selected: ", newValue)
                                            setSelectedStateComboBox(newValue)
                                        }
                                        onChangeCombo(filter.id, newValue.id);
                                    } else {
                                        onChangeCombo(filter.id, null);
                                    }
                                }}
                                options={options}
                                getOptionLabel={(option) => option.title}
                                style={{ width: 195, marginRight: 10 }}
                                autoHighlight
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        size="small"
                                        variant="outlined"
                                        label={filter.label}
                                        type={'text'}
                                    />
                                )}
                            />
                        );
                    }
                    if (filter.id === 'mediadorCodigo') {
                        if (showCoverage) {
                            return (
                                <TextField
                                    size="small"
                                    id={filter.id}
                                    key={filter.id}
                                    variant="outlined"
                                    label={filter.label}
                                    type={filter.type ? filter.type : 'text'}
                                    name={filter.id}
                                    onChange={onChange}
                                    value={search[filter.id] || ''}
                                    style={{ width: 195 }}
                                />
                            );
                        } else {
                            return '';
                        }
                    }
                    return (
                        <TextField
                            size="small"
                            id={filter.id}
                            key={filter.id}
                            variant="outlined"
                            label={filter.label}
                            type={filter.type ? filter.type : 'text'}
                            name={filter.id}
                            onChange={onChange}
                            value={search[filter.id] || ''}
                            style={{ width: 195 }}
                        />
                    );
                })}
                <div className="button-row">
                    <Button
                        color="primary"
                        size="small"
                        variant="contained"
                        onClick={handleSearch}
                    >
                        {i18n.t('filters.index.label.buscar')}
                    </Button>
                    <Button
                        variant="contained"
                        size="small"
                        onClick={clearForm}
                    >
                        {i18n.t('filters.index.label.limpiarfiltro')}
                    </Button>
                </div>
            </div>
            <div className="buttons">
                {superUser && (
                    <>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={handleNewCaso}
                            startIcon={<AddCircleOutlineIcon />}
                        >
                            {i18n.t('filters.index.label.altacaso')}
                        </Button>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={handleOpenCarga}
                            startIcon={<PublishIcon />}
                        >
                            {i18n.t('filters.index.label.cargamasiva')}
                        </Button>
                    </>
                )}
                <Button
                    color="primary"
                    variant="contained"
                    onClick={() => exportCaso(search)(casosDispatch)}
                    startIcon={<ImportExportIcon />}
                >
                    {i18n.t('filters.index.label.exportar')}
                </Button>
            </div>
        </div>
    );
};

export default Filters;
