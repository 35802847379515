import { useContext, useState } from "react";
import CargaMasiva from "../../components/CargaMasiva";
import CasoForm from "../../components/CasoForm";
import CasosTable from "../../components/CasosTable";
import Filters from "../../components/Filters";
import NewCasoForm from "../../components/NewCasoForm";
import { GlobalContext } from "../../context/Provider";

import "./inbox.scss";

const Inbox = () => {
  const {
    casosState: { insureds, selected },
  } = useContext(GlobalContext);

  const [openCarga, setOpenCarga] = useState(false);
  const [openForm, setOpenForm] = useState(false);

  const handleOpenCarga = () => {
    setOpenCarga(true);
  };

  const handleOpenForm = () => {
    setOpenForm(true);
  };

  const handleCloseCarga = () => {
    setOpenCarga(false);
  };

  const handleCloseForm = () => {
    setOpenForm(false);
  };

  return (
    <div className="inbox">
      <Filters
        handleOpenCarga={handleOpenCarga}
        handleOpenForm={handleOpenForm}
      />
      {insureds.length > 0 && <CasosTable />}
      {selected && <CasoForm open={!!selected} />}
      <NewCasoForm open={openForm} handleCloseForm={handleCloseForm} />
      <CargaMasiva openCarga={openCarga} handleCloseCarga={handleCloseCarga} />
    </div>
  );
};

export default Inbox;
