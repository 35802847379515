import { useState, useContext, useEffect } from "react";
import { GlobalContext } from "../../context/Provider";

import { login } from "../../context/actions/auth/login";

const useForm = () => {
  const [form, setForm] = useState({});
  const {
    authDispatch,
    authState: { loading, error, data },
    setLoginOK,
  } = useContext(GlobalContext);

  const onChange = (e) => {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setForm({ ...form, [name]: value });
  };

  const loginFormValid = !form.username?.length || !form.password?.length;

  const onSubmit = () => {

    login({ password: form.password, username: form.username, setLoginOK })(authDispatch);
  };

  useEffect(() => {
    if (data) {
      if (data.company) {
      }
    }
  }, [data]);

  return { form, onChange, loading, error, loginFormValid, onSubmit };
};

export default useForm;
